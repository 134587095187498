import axios from 'axios'
import {
	DEAL_LIST_REQUEST,
	DEAL_LIST_SUCCESS,
	DEAL_LIST_FAIL,
} from '../constants/dealConstants'

export const listDeals =
	(keyword = '') =>
	async (dispatch) => {
		try {
			dispatch({
				type: DEAL_LIST_REQUEST,
			})

			const { data } = await axios.get(`/api/deals?keyword=${keyword}`)

			dispatch({
				type: DEAL_LIST_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: DEAL_LIST_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
