import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	Container,
	Table,
	Button,
	Card,
	Col,
	Row,
	Modal,
	Form,
} from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import OrgAdminNav from '../../../components/OrgAdminNav/OrgAdminNav'
import { getUserDetails } from '../../../services/actions/userActions'
import { getOrgDetails } from '../../../services/actions/orgActions'
import {
	listOrgUserMemberships,
	createUserMembership,
} from '../../../services/actions/userMembershipActions'
import './OrgManageScreen.css'
import { USERMEMBERSHIP_CREATE_RESET } from '../../../services/constants/userMembershipConstants'

const OrgManageScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { slug: orgSlug } = useParams()

	const [showModal, setShowModal] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const userMembershipCreate = useSelector(
		(state) => state.userMembershipCreate
	)
	const { success: userMembershipCreateSuccess } = userMembershipCreate

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	const [newMember, setNewMember] = useState({
		membershipProgram: '',
		fullName: '',
		email: '',
		phone: '',
		dob: '',
		tShirtSize: '',
		totalAmountPaid: '',
		paymentMethod: '',
		membershipStatus: 'active',
		origin: 'manual',
		org: org?._id,
	}) // State for form inputs

	// User login details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: userCredInfo } = userLogin

	// User details from state
	const { user } = useSelector((state) => state.userDetails)

	const userMembershipListOrg = useSelector(
		(state) => state.userMembershipListOrg
	)
	const { userMemberships } = userMembershipListOrg

	useEffect(() => {
		if (!org?._id || org?.slug !== orgSlug) {
			dispatch(getOrgDetails(orgSlug))
		}
		if (org?._id && org?.slug === orgSlug) {
			dispatch(listOrgUserMemberships(org._id))
		}
	}, [orgSlug, org, dispatch])

	useEffect(() => {
		if (!userCredInfo) {
			navigate(`/login?redirect=org/${orgSlug}/manage`)
		}
		if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
		if (user?._id) {
			const userMembership = user?.orgRoles?.find(
				(role) => role.orgId == org?._id
			)
			const myUserType = userCredInfo.isAdmin
				? 'admin'
				: userMembership?.roleType

			if (org._id && !(myUserType === 'admin' || myUserType === 'manager')) {
				navigate('/')
			}
		}
	}, [dispatch, navigate, userCredInfo, user, org])

	// Reload members list when a new member is added
	useEffect(() => {
		if (userMembershipCreateSuccess) {
			dispatch(listOrgUserMemberships(org._id))
			setShowSuccessMessage(true)

			const timer = setTimeout(() => {
				setShowSuccessMessage(false)
				dispatch({ type: USERMEMBERSHIP_CREATE_RESET })
			}, 5000)

			return () => clearTimeout(timer) // Cleanup timer on component unmount
		}
	}, [userMembershipCreateSuccess])

	//Dashboard Figures
	const activeUserMemberships = userMemberships
		?.filter((userMembership) => {
			return userMembership.membershipStatus === 'active'
		})
		.map((userMembership) => {
			return {
				...userMembership,
				totalMembershipPaymentsToOrg:
					userMembership.origin === 'membershipFlow'
						? userMembership.totalAmountPaid /
						  (1 + (userMembership.membershipProgram?.taxRate || 0))
						: userMembership.totalAmountPaid,
				cleanOrigin:
					userMembership.origin === 'membershipFlow'
						? 'Flow'
						: userMembership.origin === 'manual'
						? 'Manual'
						: 'Other',
			}
		})

	const collectedActiveMemberships = sum(
		userMemberships?.filter((userMembership) => {
			return userMembership.status !== 'Open'
		}),
		'totalAmountPaid'
	)
	const orgEarningsActiveMemberships = sum(
		userMemberships?.filter((userMembership) => {
			return userMembership.status === 'Paid in full'
		}),
		'basePrice'
	)

	const orgPayouts = org?.payouts ? sum(org?.payouts, 'amount') : 0
	const orgRemaining = orgEarningsActiveMemberships - (orgPayouts ?? 0)
	//End Dashboard Figures

	function sum(arr, key) {
		let res
		if (arr?.length) {
			res = arr.reduce((a, b) => a + (b[key] || 0), 0)
		}
		return res
	}

	let userMembershipsExport = activeUserMemberships?.map(
		({
			membershipProgram: { name: membership, taxRate },
			updatedAt: lastUpdated,
			membershipStatus,
			basePrice,
			taxPrice,
			totalPrice,
			totalAmountPaid: totalCollected,
			totalAmountRemaining: totalRemaining,
			cleanOrigin: membershipOrigin,
			totalMembershipPaymentsToOrg,
			user: { name: user, email, phone },
		}) => {
			const result = {
				membership,
				user,
				email,
				phone,
				membershipStatus,
				membershipOrigin,
				totalMembershipPaymentsToOrg,
				lastUpdated,
			}

			// Conditionally add basePrice, taxPrice, and totalCollected if userCredInfo.isAdmin is true
			if (userCredInfo.isAdmin) {
				result.basePrice = basePrice
				result.taxPrice = taxPrice
				result.totalPrice = totalPrice
				result.totalCollected = totalCollected
				result.totalRemaining = totalRemaining
			}

			return result
		}
	)

	const handleAddMemberClick = () => {
		setShowModal(true)
	}

	const handleCloseModal = () => {
		setErrorMessage('')
		setShowModal(false)
		setNewMember({
			fullName: '',
			email: '',
			phone: '',
			dob: '',
			tShirtSize: '',
			totalAmountPaid: '',
			paymentMethod: '',
			membershipStatus: 'active',
			origin: 'manual',
			org: org?._id,
		})
	}

	const validateForm = () => {
		// Basic form validations
		if (!newMember.membershipProgram) return 'Membership program is required'
		if (!newMember.fullName) return 'Full Name is required'
		if (!newMember.email) return 'Email is required'
		if (!newMember.totalAmountPaid) return 'Total Amount Paid is required'
		if (!newMember.paymentMethod) return 'Payment Method is required'

		// Check if the email already exists for the selected membership program
		const isDuplicateMember = userMemberships?.some(
			(userMembership) =>
				userMembership.user.email === newMember.email &&
				userMembership.membershipProgram._id === newMember.membershipProgram &&
				userMembership.membershipStatus === 'active'
		)

		if (isDuplicateMember) {
			return 'This email is already a member of the selected membership program'
		}

		return null // No validation errors
	}

	const handleSaveMember = () => {
		const error = validateForm()
		if (error) {
			setErrorMessage(error) // Set error message if validation fails
			return
		}

		newMember.org = org?._id
		// Dispatch action to save the new member
		dispatch(createUserMembership(newMember))
		handleCloseModal() // Close modal after saving
	}

	return (
		<>
			<OrgAdminNav />
			<Container className='my-4'>
				{showSuccessMessage && (
					<Message variant='success'>Member successfully added</Message>
				)}
				{!userMemberships || org?.slug !== orgSlug ? (
					<Loader />
				) : userMemberships.length > 0 ? (
					<>
						<Row className='align-items-center'>
							<Col>
								<h1>Memberships</h1>
							</Col>
							<Col className='text-right'>
								<Button
									className='my-3 mx-2'
									variant='light'
									onClick={handleAddMemberClick}
								>
									Add Member
								</Button>
								{userMembershipsExport && (
									<CSVLink
										data={userMembershipsExport}
										filename={`Memberships - ${org?.name}.csv`}
									>
										<Button className='my-3'>Export Memberships</Button>
									</CSVLink>
								)}
							</Col>
						</Row>
						<Row>
							<Col lg='3' md='6' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Active Memberships</Card.Header>
									<Card.Body>
										<Card.Title>{activeUserMemberships.length}</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							{userCredInfo.isAdmin && (
								<Col lg='3' md='6' sm='12'>
									<Card className='my-2' bg='light'>
										<Card.Header>Total Collected</Card.Header>
										<Card.Body>
											<Card.Title>
												$
												{collectedActiveMemberships
													? collectedActiveMemberships.toFixed(2)
													: Number(0).toFixed(2)}
											</Card.Title>
										</Card.Body>
									</Card>
								</Col>
							)}

							<Col lg='3' md='6' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Earnings</Card.Header>
									<Card.Body>
										<Card.Title>
											$
											{orgEarningsActiveMemberships
												? orgEarningsActiveMemberships?.toFixed(2)
												: Number(0).toFixed(2)}
										</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							<Col lg='3' md='6' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Payouts Issued</Card.Header>
									<Card.Body>
										<Card.Title>${orgPayouts?.toFixed(2) ?? '0.00'}</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							{!userCredInfo.isAdmin && (
								<Col lg='3' md='6' sm='12'>
									<Card className='my-2' bg='light'>
										<Card.Header>Total Org Payouts Remaining</Card.Header>
										<Card.Body>
											<Card.Title>
												$
												{orgRemaining
													? orgRemaining.toFixed(2)
													: Number(0).toFixed(2)}
											</Card.Title>
										</Card.Body>
									</Card>
								</Col>
							)}
						</Row>

						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th>MEMBERSHIP</th>
									<th>USER</th>
									<th>EMAIL</th>
									<th>PHONE</th>
									<th>STATUS</th>
									{userCredInfo.isAdmin ? <th>TOTAL PRICE</th> : ''}
									{userCredInfo.isAdmin ? <th>TOTAL COLLECTED</th> : ''}
									<th>ORIGIN</th>
									<th>COLLECTED DUES</th>
									{userCredInfo.isAdmin ? <th></th> : ''}
								</tr>
							</thead>
							<tbody>
								{activeUserMemberships.map((userMembership) => (
									<tr key={userMembership._id}>
										<td>{userMembership?.membershipProgram?.name}</td>
										<td>
											{userMembership?.user && userMembership?.user?.name}
										</td>
										<td>{userMembership?.user?.email}</td>
										<td>{userMembership?.user?.phone}</td>
										<td>{userMembership?.membershipStatus}</td>
										{userCredInfo.isAdmin ? (
											<td>${userMembership?.totalPrice}</td>
										) : (
											''
										)}
										{userCredInfo.isAdmin ? (
											<td>${userMembership?.totalAmountPaid}</td>
										) : (
											''
										)}
										<td>{userMembership?.cleanOrigin}</td>
										<td>
											$
											{(userMembership?.totalMembershipPaymentsToOrg).toFixed(
												2
											)}
										</td>

										{userCredInfo.isAdmin ? (
											<td>
												<NavLink
													to={`/org/${orgSlug}/${userMembership?.membershipProgram?.name}/membership/${userMembership?._id}`}
												>
													<Button variant='light' className='btn-sm'>
														Details
													</Button>
												</NavLink>
											</td>
										) : (
											''
										)}
									</tr>
								))}
							</tbody>
						</Table>
						<Modal
							show={showModal}
							onHide={handleCloseModal}
							dialogClassName='custom-modal'
						>
							<Modal.Header closeButton>
								<Modal.Title>Add New Member</Modal.Title>
							</Modal.Header>
							<Modal.Body className='modal-body-scrollable'>
								<Form>
									<Form.Group controlId='membershipProgram'>
										<Form.Label>Membership Program*</Form.Label>
										<Form.Control
											required
											as='select'
											value={newMember.membershipProgram}
											onChange={(e) =>
												setNewMember({
													...newMember,
													membershipProgram: e.target.value, // Store the _id
												})
											}
										>
											<option value=''>Select membership</option>
											{org?.membershipPrograms?.map((program) => (
												<option key={program._id} value={program._id}>
													{program.name}
												</option>
											))}
										</Form.Control>
									</Form.Group>

									<Form.Group controlId='fullName'>
										<Form.Label>Full Name*</Form.Label>
										<Form.Control
											required
											type='text'
											placeholder='Enter full name'
											value={newMember.fullName}
											onChange={(e) =>
												setNewMember({ ...newMember, fullName: e.target.value })
											}
										/>
									</Form.Group>

									<Form.Group controlId='email'>
										<Form.Label>Email*</Form.Label>
										<Form.Control
											required
											type='email'
											placeholder='Enter email'
											value={newMember.email}
											onChange={(e) =>
												setNewMember({ ...newMember, email: e.target.value })
											}
										/>
									</Form.Group>

									<Form.Group controlId='phone'>
										<Form.Label>Phone</Form.Label>
										<Form.Control
											required
											type='text'
											placeholder='Enter phone number'
											value={newMember.phone}
											onChange={(e) =>
												setNewMember({ ...newMember, phone: e.target.value })
											}
										/>
									</Form.Group>

									<Form.Group controlId='dob'>
										<Form.Label>Date of Birth</Form.Label>
										<Form.Control
											required
											type='date'
											value={newMember.dob}
											onChange={(e) =>
												setNewMember({ ...newMember, dob: e.target.value })
											}
										/>
									</Form.Group>

									<Form.Group controlId='tShirtSize'>
										<Form.Label>T-Shirt Size</Form.Label>
										<Form.Control
											required
											as='select'
											value={newMember.tShirtSize}
											onChange={(e) =>
												setNewMember({
													...newMember,
													tShirtSize: e.target.value,
												})
											}
										>
											<option value=''>Select size</option>
											<option value='S'>Small</option>
											<option value='M'>Medium</option>
											<option value='L'>Large</option>
											<option value='XL'>Extra Large</option>
										</Form.Control>
									</Form.Group>

									<Form.Group controlId='totalAmountPaid'>
										<Form.Label>Total Amount Paid ($)*</Form.Label>
										<Form.Control
											required
											type='number'
											placeholder='Enter amount paid'
											value={newMember.totalAmountPaid}
											onChange={(e) =>
												setNewMember({
													...newMember,
													totalAmountPaid: e.target.value,
												})
											}
										/>
									</Form.Group>
									<Form.Group controlId='paymentMethod'>
										<Form.Label>Payment Method*</Form.Label>
										<Form.Control
											required
											as='select'
											value={newMember.paymentMethod}
											onChange={(e) =>
												setNewMember({
													...newMember,
													paymentMethod: e.target.value,
												})
											}
										>
											<option value=''>Select payment method</option>
											<option value='Cash'>Cash</option>
											<option value='Venmo'>Venmo</option>
											<option value='Zelle'>Zelle</option>
											<option value='Check'>Check</option>
											<option value='Other'>Other</option>
											<option value='No Payment'>No Payment</option>
										</Form.Control>
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								{errorMessage && (
									<Col xs={12}>
										<Message variant='danger'>{errorMessage}</Message>
									</Col>
								)}
								<Row className='w-100'>
									<Col className='text-right'>
										<Button variant='secondary' onClick={handleCloseModal}>
											Close
										</Button>
										<Button variant='primary' onClick={handleSaveMember}>
											Save Member
										</Button>
									</Col>
								</Row>
							</Modal.Footer>
						</Modal>
					</>
				) : (
					<h2>No memberships have been purchased</h2>
				)}
			</Container>
		</>
	)
}

export default OrgManageScreen
