import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from '../../../services/actions/userActions'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'

const ManageOrgListScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const orgList = useSelector((state) => state.orgList)
	const { loading, error } = orgList

	// User login details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// User details from state, provide an empty object as fallback
	const { user = {} } = useSelector((state) => state.userDetails)

	// Org Roles, provide a fallback empty array to avoid errors
	const { orgRoles = [] } = user

	useEffect(() => {
		if (!userInfo) {
			navigate('/login')
			return
		}

		if (!user._id) {
			dispatch(getUserDetails('profile'))
		}
	}, [dispatch, userInfo, user._id, navigate])

	return (
		!!Object.keys(user).length && (
			<Container className='my-3'>
				<Row className='align-items-center'>
					<Col>
						<h1>Organizations</h1>
					</Col>
				</Row>
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>NAME</th>
								<th>TYPE</th>

								<th></th>
							</tr>
						</thead>
						<tbody>
							{orgRoles.map((org) => (
								<tr key={org.orgId}>
									<td>
										<Link to={`/org/${org.orgSlug}/manage`}>{org.orgName}</Link>
									</td>
									<td>{org.orgCategory}</td>

									<td>
										<NavLink
											to='route'
											target='_blank'
											onClick={(event) => {
												event.preventDefault()
												window.open(`../org/${org.orgSlug}`)
											}}
										>
											<Button variant='light' className='btn-sm'>
												<i className='fas fa-external-link-square-alt fa-lg'></i>
											</Button>
										</NavLink>
										<NavLink to={`/org/${org.orgSlug}/manage`}>
											<Button variant='light' className='btn-sm'>
												<i className='fas fa-pen-square fa-lg'></i>
											</Button>
										</NavLink>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</Container>
		)
	)
}

export default ManageOrgListScreen
