import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
	getExperienceDetails,
	updateExperience,
} from '../../services/actions/experienceActions'

const ExperienceListTripOptionScreen = () => {
	const { id: experienceId } = useParams()
	const navigate = useNavigate()

	const dispatch = useDispatch()
	const [tripOption, setTripOption] = useState([])

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo || !userInfo.isAdmin) {
			navigate('/login') // Redirect to login if not logged in or not admin
		} else if (!experience.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		} else {
			setTripOption(experience.tripOption || [])
		}
	}, [dispatch, navigate, userInfo, successUpdate, experience, experienceId])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			const newTripOption = tripOption.filter((item) => item._id !== id)
			setTripOption(newTripOption)

			dispatch(
				updateExperience({
					_id: experienceId,
					tripOption: newTripOption,
				})
			)
			dispatch(getExperienceDetails(experienceId))
		}
	}

	const createTripOptionItemHandler = () => {
		const newTripOption = [
			...tripOption,
			{
				dayName: 'Day',
				sortOrder: 0,
				title: 'Title',
				description: 'Description',
				image: 'Image',
			},
		]

		setTripOption(newTripOption)

		dispatch(
			updateExperience({
				_id: experienceId,
				tripOption: newTripOption,
			})
		)
		dispatch(getExperienceDetails(experienceId))
	}

	return (
		<>
			<Row className='align-items-center my-4'>
				<Col>
					<h1>Trip Options</h1>
				</Col>
				<Col className='text-right'>
					<Button onClick={createTripOptionItemHandler}>
						<i className='fas fa-plus'></i> Create Trip Option
					</Button>
				</Col>
			</Row>
			{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
			{loading || loadingUpdate ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : tripOption.length > 0 ? (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th>TITLE</th>
							<th>DESC</th>
							<th>PRICE</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{tripOption.map((item) => (
							<tr key={item._id}>
								<td>{item.title}</td>
								<td>{item.description}</td>
								<td>{item.fullPrice}</td>

								<td>
									<NavLink
										to={`/admin/experience/${experience._id}/TripOption/${item._id}`}
									>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-edit'></i>
										</Button>
									</NavLink>
									<Button
										variant='danger'
										className='btn-sm'
										onClick={() => deleteHandler(item._id)}
									>
										<i className='fas fa-trash'></i>
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			) : (
				<h2>No trip options have been created</h2>
			)}
		</>
	)
}

export default ExperienceListTripOptionScreen
