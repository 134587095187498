import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
	Container,
	Table,
	Button,
	Col,
	Row,
	Modal,
	Form,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import DetailHeader from '../../components/DetailHeader'
import {
	getExperienceDetails,
	updateExperience,
} from '../../services/actions/experienceActions'
import { listOrgs } from '../../services/actions/orgActions'

const ExperienceListOrgsScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { id: experienceId } = useParams()

	const [showModal, setShowModal] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)
	const [newExperienceOrg, setNewExperienceOrg] = useState(null)
	const [experienceOrgs, setExperienceOrgs] = useState([])

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const orgList = useSelector((state) => state.orgList)
	const { orgs } = orgList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!experience.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		} else {
			setExperienceOrgs(experience.experienceOrgs)
		}

		if (!userInfo.isAdmin) {
			navigate('/login')
		}
		dispatch(listOrgs())
	}, [
		dispatch,
		navigate,
		userInfo,
		successUpdate,
		experience,
		experienceId,
		experienceOrgs,
	])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			const newExperienceOrgs = experienceOrgs.filter((experienceOrg) => {
				return experienceOrg.experienceOrgId !== experienceOrg
			})

			setExperienceOrgs(newExperienceOrgs)

			dispatch(
				updateExperience({
					_id: experienceId,
					experienceOrgs: newExperienceOrgs,
				})
			)
			dispatch(getExperienceDetails(experienceId))
		}
	}

	const handleAddOrgClick = () => {
		setShowModal(true)
	}

	const handleCloseModal = () => {
		setErrorMessage('')
		setShowModal(false)
		setNewExperienceOrg(null)
	}
	const [errorMessage, setErrorMessage] = useState(null)

	const validateForm = () => {
		return null
		// Basic form validations
		// if (false) return 'Membership program is required'
		// if (false) return 'Full Name is required'
		// if (!newMember.email) return 'Email is required'
		// if (!newMember.totalAmountPaid) return 'Total Amount Paid is required'
		// if (!newMember.paymentMethod) return 'Payment Method is required'

		// Check if the email already exists for the selected membership program
		// const isDuplicateMember = userMemberships?.some(
		// 	(userMembership) =>
		// 		userMembership.user.email === newMember.email &&
		// 		userMembership.membershipProgram._id === newMember.membershipProgram &&
		// 		userMembership.membershipStatus === 'active'
		// )

		// if (isDuplicateMember) {
		// 	return 'This email is already a member of the selected membership program'
		// }

		return null // No validation errors
	}

	const handleAddExperienceOrg = () => {
		const error = validateForm()
		if (error) {
			setErrorMessage(error) // Set error message if validation fails
			return
		}

		experienceOrgs.push({ org: newExperienceOrg })

		dispatch(
			updateExperience({
				_id: experienceId,
				experienceOrgs: experienceOrgs,
			})
		)
		dispatch(getExperienceDetails(experienceId))

		handleCloseModal() // Close modal after saving
	}

	return (
		<>
			<DetailHeader />
			<Container>
				<Row className='align-items-center'>
					<Col>
						<h1>Participating Organizations</h1>
					</Col>
					<Col className='text-right'>
						<Button className='my-3' onClick={handleAddOrgClick}>
							<i className='fas fa-plus'></i> Add Organization
						</Button>
					</Col>
				</Row>
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading || loadingUpdate ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>Organization</th>
								<th
									style={{ width: '100px', maxWidth: '100px' }}
									className='text-center'
								></th>
							</tr>
						</thead>
						<tbody>
							{experienceOrgs.map((experienceOrg) => (
								<tr key={experienceOrg?.experienceOrgId}>
									<td>{experienceOrg?.orgName}</td>
									<td
										style={{ width: '100px', maxWidth: '100px' }}
										className='text-center'
									>
										<Button
											variant='danger'
											className='btn-sm'
											onClick={() =>
												deleteHandler(experienceOrg.experienceOrgId)
											}
										>
											<i className='fas fa-trash'></i>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</Container>
			<Modal
				show={showModal}
				onHide={handleCloseModal}
				dialogClassName='custom-modal'
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Organization</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modal-body-scrollable'>
					<Form>
						<Form.Group controlId='organization'>
							<Form.Label>Organization*</Form.Label>
							<Form.Control
								required
								as='select'
								value={newExperienceOrg}
								onChange={(e) => setNewExperienceOrg(e.target.value)}
							>
								<option value=''>Select an Organization</option>
								{orgs.map((org) => (
									<option key={org._id} value={org._id}>
										{org.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					{errorMessage && (
						<Col xs={12}>
							<Message variant='danger'>{errorMessage}</Message>
						</Col>
					)}
					<Row className='w-100'>
						<Col className='text-right'>
							<Button variant='secondary' onClick={handleCloseModal}>
								Close
							</Button>
							<Button variant='primary' onClick={handleAddExperienceOrg}>
								Add Organization
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ExperienceListOrgsScreen
