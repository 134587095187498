import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'

const DetailHeader = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails

	const location = useLocation()
	const experienceId = experience?._id

	// Helper function to generate dynamic link
	const generateLink = (newPathSegment) => {
		const basePath = location.pathname.split('/').slice(0, -1).join('/') // Remove the last segment
		return `${basePath}/${newPathSegment}`
	}

	return (
		<>
			{experience && (
				<Navbar
					style={{ backgroundColor: '#cccccc' }}
					variant='dark'
					expand='sm'
					collapseOnSelect
					className='navbar-custom'
				>
					<Container>
						<Navbar.Brand as={Link} to={generateLink('details')}>
							{experience.name}
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						{experience?.type !== 'External' && (
							<Navbar.Collapse id='basic-navbar-nav'>
								<Nav className='ml-auto'>
									<Nav.Link as={Link} to={generateLink('details')}>
										Details
									</Nav.Link>
									<Nav.Link as={Link} to={generateLink('itinerary')}>
										Itinerary
									</Nav.Link>
									<Nav.Link as={Link} to={generateLink('accommodations')}>
										Accommodation
									</Nav.Link>
									<Nav.Link as={Link} to={generateLink('destination')}>
										Destination
									</Nav.Link>
									<Nav.Link as={Link} to={generateLink('tripoption')}>
										Trip Options
									</Nav.Link>
									<Nav.Link as={Link} to={generateLink('orders')}>
										Orders
									</Nav.Link>
									<Nav.Link as={Link} to={generateLink('leads')}>
										Leads
									</Nav.Link>
								</Nav>
							</Navbar.Collapse>
						)}
					</Container>
				</Navbar>
			)}
		</>
	)
}

export default DetailHeader
