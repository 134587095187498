import React from 'react'
import { useSelector } from 'react-redux'
import './DateCard.css'

const DateCard = ({ date }) => {
	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails
	const dateObj = new Date(date)
	const currentYear = new Date().getFullYear()

	const month = dateObj
		.toLocaleString('default', { month: 'short' })
		.toUpperCase()
	const day = dateObj.getDate()
	const year = dateObj.getFullYear()

	const isManagePath = window.location.pathname.includes('manage')

	return (
		<div className='date-card'>
			<div
				className='date-card__month'
				style={{
					color: isManagePath ? org?.primaryColor ?? '#f28f61' : '#f28f61',
				}}
			>
				{month}
			</div>
			<div className='date-card__day'>{day}</div>
			{year !== currentYear && <div className='date-card__year'>{year}</div>}
		</div>
	)
}

export default DateCard
