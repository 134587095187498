import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {
	getExperienceDetails,
	updateExperience,
} from '../../services/actions/experienceActions'
import { EXPERIENCE_UPDATE_RESET } from '../../services/constants/experienceConstants'

const ExperienceEditTripOptionScreen = () => {
	const { id: experienceId, tripoptionid: tripOptionId } = useParams() // Get route params
	const navigate = useNavigate() // Replace navigate

	const [uploading, setUploading] = useState(false)
	const [optionStatus, setOptionStatus] = useState('')
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [image, setImage] = useState('')
	const [fullPrice, setFullPrice] = useState('')
	const [sizes, setSizes] = useState('')
	const [showSizing, setSizingOption] = useState('')

	const dispatch = useDispatch()

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo?.isAdmin) {
			navigate('/') // Redirect to home if not admin
		} else if (successUpdate) {
			dispatch({ type: EXPERIENCE_UPDATE_RESET })
			dispatch(getExperienceDetails(experienceId))
			navigate(`/admin/experience/${experienceId}/tripOption`) // Redirect to trip options list
		} else {
			if (!experience.name || experience._id !== experienceId) {
				dispatch(getExperienceDetails(experienceId))
			} else {
				const tripOptionIndex = experience.tripOption.findIndex(
					(item) => item._id === tripOptionId
				)
				const tripOption = experience.tripOption[tripOptionIndex]
				setTitle(tripOption?.title || '')
				setDescription(tripOption?.description || '')
				setImage(tripOption?.image || '')
				setFullPrice(tripOption?.fullPrice || '')
				setOptionStatus(tripOption?.optionStatus || '')
				setSizes(tripOption?.sizes || '')
				setSizingOption(tripOption?.showSizing || false)
			}
		}
	}, [
		experience,
		experienceId,
		dispatch,
		navigate,
		successUpdate,
		tripOptionId,
		userInfo,
	])

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('image', file)
		setUploading(true)

		try {
			const config = {
				headers: {
					'Content-type': 'multipart/form-data',
				},
			}
			const { data } = await axios.post('/api/upload', formData, config)

			setImage(data)
			setUploading(false)
		} catch (error) {
			console.error(error)
			setUploading(false)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()

		const newTripOption = experience.tripOption.map((item) =>
			item._id === tripOptionId
				? {
						...item,
						title,
						description,
						image,
						fullPrice,
						optionStatus,
						showSizing,
				  }
				: item
		)
		dispatch(
			updateExperience({
				_id: experienceId,
				tripOption: newTripOption,
			})
		)
	}

	return (
		<>
			<Container>
				<Link
					to={`/admin/experience/${experienceId}/tripOption`}
					className='btn btn-light my-3'
				>
					Go Back
				</Link>
				<FormContainer>
					<h1>Edit Trip Option</h1>
					{loadingUpdate && <Loader />}
					{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
					{loading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error}</Message>
					) : (
						<Form onSubmit={submitHandler}>
							<Form.Group controlId='optionStatus'>
								<h2>Status</h2>
								<Form.Label>Trip Option Status</Form.Label>
								<Form.Control
									as='select'
									type='text'
									value={optionStatus}
									placeholder='Select a status...'
									required
									onChange={(e) => setOptionStatus(e.target.value)}
								>
									<option>Available</option>
									<option>Unavailable</option>
								</Form.Control>
							</Form.Group>

							<Form.Group controlId='title'>
								<Form.Label>Title</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter title'
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='showSizing'>
								<Form.Check
									type='switch'
									id='sizingSwitch'
									label='Has sizes?'
									onChange={(e) => setSizingOption(e.target.checked)}
									checked={!!showSizing}
								/>
							</Form.Group>

							<Form.Group controlId='description'>
								<Form.Label>Description</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter description'
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='image'>
								<Form.Label>Image</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter image url'
									value={image}
									onChange={(e) => setImage(e.target.value)}
								></Form.Control>
								<Form.File
									id='image-file'
									label='Choose File'
									custom
									onChange={uploadFileHandler}
								></Form.File>
								{uploading && <Loader />}
							</Form.Group>

							<Form.Group controlId='fullPrice'>
								<Form.Label>Total Price</Form.Label>
								<Form.Control
									type='number'
									placeholder='Enter price of add-on'
									value={fullPrice}
									onChange={(e) => setFullPrice(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Button type='submit' variant='primary'>
								Update
							</Button>
						</Form>
					)}
				</FormContainer>
			</Container>
		</>
	)
}

export default ExperienceEditTripOptionScreen
