import React from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import headerlogo from '../images/headerlogo.png'
import { logout } from '../services/actions/userActions'

const Header = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const path = location.pathname

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userDetails = useSelector((state) => state.userDetails)
	const hasOrgRoles = userDetails?.user?.orgRoles?.length > 0

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	const logoImage = path.startsWith('/org') && org?.logo ? org.logo : headerlogo

	const logoutHandler = () => {
		dispatch(logout())
		navigate('/')
	}

	return (
		<header>
			<Navbar
				style={{ backgroundColor: 'black', fontSize: '15px' }}
				variant='dark'
				expand='lg'
				collapseOnSelect
			>
				<Container>
					<Link to='/home'>
						<Navbar.Brand className='m-0 p-0'>
							<img
								alt=''
								src={logoImage}
								className='d-inline-block align-top p-0 m-0'
								style={{ maxHeight: '72px' }}
							/>
						</Navbar.Brand>
					</Link>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ml-auto'>
							{userInfo ? (
								<NavDropdown
									title={userInfo.name}
									id='username'
									className='mb-2'
								>
									<NavDropdown.Item as={Link} to='/home'>
										Home
									</NavDropdown.Item>
									{hasOrgRoles && (
										<NavDropdown.Item as={Link} to='/manage/org-list'>
											Manage Orgs
										</NavDropdown.Item>
									)}
									<NavDropdown.Item as={Link} to='/profile'>
										Edit Profile
									</NavDropdown.Item>
									<NavDropdown.Item onClick={logoutHandler}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<Nav.Link as={Link} to='/login' className='mr-3 mt-1'>
									Login
								</Nav.Link>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	)
}

export default Header
