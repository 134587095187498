import React from 'react'
import { Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Membership = ({ userMembership }) => {
	const { membershipProgram: program, org } = userMembership

	return (
		<>
			{program && (
				<NavLink style={{ maxWidth: '200px' }} to={`/org/${org.slug}`}>
					<li
						className='cards__item m-0'
						style={{
							boxShadow: '0px 0px 15px #f5f5f5',
							flex: '0 0 200px',
						}}
					>
						<div className='cards__item__link'>
							<figure
								className='cards__item__pic-wrap-no-box'
								data-category={program.name}
							>
								<img
									className='cards__item__img'
									alt='Travel Pic'
									src={program.image}
								/>
							</figure>
							<div className='cards__item__info'>
								<h5 className='cards__item__title mb-2'>{org.name}</h5>
							</div>

							<Button
								href={`/org/${org.slug}`}
								className='btn-block'
								type='button'
							>
								View Organization
							</Button>
						</div>
					</li>
				</NavLink>
			)}
		</>
	)
}

export default Membership
