import OrgDeal from './OrgDeal'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'

const OrgDealsGallery = ({ OrgDeals, title }) => {
	const activeDeals = OrgDeals?.filter((deal) => {
		return deal.status === 'active'
	})

	return (
		<div className='gallery'>
			<Container>
				<Row>
					<Col md={12} className='gallery-container'>
						<ListGroup variant='flush'>
							{activeDeals?.length > 0 && (
								<>
									<ListGroup.Item>
										<h2>{title}</h2>
										<div className='cards__wrapper'>
											<ul className='cards__items'>
												{activeDeals.map((deal) => (
													<Col
														key={deal._id}
														xs={10}
														sm={8}
														md={6}
														lg={3}
														xl={3}
													>
														<OrgDeal deal={deal} />
													</Col>
												))}
											</ul>
										</div>
									</ListGroup.Item>
								</>
							)}
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

OrgDealsGallery.defaultProps = {
	title: 'Exclusive Deals',
}

export default OrgDealsGallery
