import React from 'react'
import { useSelector } from 'react-redux'
import Membership from '../Membership/Membership'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'
import './MyMembershipsGallery.css'

const MyMembershipsGallery = ({ title, memberships }) => {
	return (
		<div className='my-memberships-gallery'>
			<Container>
				<Row>
					<Col md={12} className='gallery-container'>
						<ListGroup variant='flush'>
							{memberships ? (
								<ListGroup.Item>
									<h2>{title}</h2>
									<div className='cards__wrapper'>
										<ul className='cards__items'>
											{memberships?.map((membership) => (
												<Col
													key={membership._id}
													xs={10}
													sm={8}
													md={6}
													lg={3}
													xl={3}
												>
													<Membership userMembership={membership} />
												</Col>
											))}
										</ul>
									</div>
								</ListGroup.Item>
							) : (
								''
							)}
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

MyMembershipsGallery.defaultProps = {
	title: 'My Memberships',
}

export default MyMembershipsGallery
