import OrgExperience from './OrgExperience'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'

const OrgExperiencesGallery = ({ orgExperiences, title }) => {
	const futureExperiences = orgExperiences?.filter((experience) => {
		const experienceDate = new Date(experience.experienceStartDate)
		return experienceDate > new Date()
	})

	return (
		<div>
			<Container>
				<Row>
					<Col md={12} className='gallery-container'>
						<ListGroup variant='flush'>
							{futureExperiences?.length > 0 && (
								<>
									<ListGroup.Item>
										<h2>{title}</h2>
										<div className='cards__wrapper'>
											<ul className='cards__items'>
												{futureExperiences.map((experience) => (
													<Col
														key={experience._id}
														xs={10}
														sm={8}
														md={6}
														lg={3}
														xl={3}
													>
														<OrgExperience experience={experience} />
													</Col>
												))}
											</ul>
										</div>
									</ListGroup.Item>
								</>
							)}
						</ListGroup>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

OrgExperiencesGallery.defaultProps = {
	title: 'Upcoming Experiences',
}

export default OrgExperiencesGallery
