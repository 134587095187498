import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	Container,
	Table,
	Button,
	Card,
	Col,
	Row,
	Modal,
	Form,
} from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import OrgAdminNav from '../../../components/OrgAdminNav/OrgAdminNav'
import { getUserDetails } from '../../../services/actions/userActions'
import { getOrgDetails } from '../../../services/actions/orgActions'
import {
	listOrgUserMemberships,
	createUserMembership,
} from '../../../services/actions/userMembershipActions'
import './OrgManagePayoutsScreen.css'
import { USERMEMBERSHIP_CREATE_RESET } from '../../../services/constants/userMembershipConstants'

const OrgManagePayoutsScreen = () => {
	const dispatch = useDispatch()
	const { slug: orgSlug } = useParams()
	const navigate = useNavigate()

	const [showModal, setShowModal] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)
	const userMembershipCreate = useSelector(
		(state) => state.userMembershipCreate
	)
	const { success: userMembershipCreateSuccess } = userMembershipCreate

	const orgDetails = useSelector((state) => state.orgDetails)
	const {
		org,
		org: { payouts },
	} = orgDetails

	const [newMember, setNewMember] = useState({
		membershipProgram: '',
		fullName: '',
		email: '',
		phone: '',
		dob: '',
		tShirtSize: '',
		totalAmountPaid: '',
		paymentMethod: '',
		membershipStatus: 'active',
		origin: 'manual',
		org: org?._id,
	}) // State for form inputs

	// User login details from state
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: userCredInfo } = userLogin

	// User details from state
	const { user } = useSelector((state) => state.userDetails)

	const userMembershipListOrg = useSelector(
		(state) => state.userMembershipListOrg
	)
	const { userMemberships } = userMembershipListOrg

	useEffect(() => {
		if (!org?._id || org?.slug !== orgSlug) {
			dispatch(getOrgDetails(orgSlug))
		}
		if (org?._id && org?.slug === orgSlug) {
			dispatch(listOrgUserMemberships(org._id))
		}
	}, [orgSlug, org, dispatch])

	useEffect(() => {
		if (!userCredInfo) {
			navigate(`/login?redirect=org/${orgSlug}/manage`)
		}
		if (!user?._id) {
			dispatch(getUserDetails('profile'))
		}
		if (user?._id) {
			const userMembership = user?.orgRoles?.find(
				(role) => role.orgId == org?._id
			)
			const myUserType = userCredInfo.isAdmin
				? 'admin'
				: userMembership?.roleType

			if (!(myUserType === 'admin' || myUserType === 'manager')) {
				navigate('/')
			}
		}
	}, [dispatch, navigate, userCredInfo, user, org])

	// Reload members list when a new member is added
	useEffect(() => {
		if (userMembershipCreateSuccess) {
			dispatch(listOrgUserMemberships(org._id))
			setShowSuccessMessage(true)

			const timer = setTimeout(() => {
				setShowSuccessMessage(false)
				dispatch({ type: USERMEMBERSHIP_CREATE_RESET })
			}, 5000)

			return () => clearTimeout(timer) // Cleanup timer on component unmount
		}
	}, [userMembershipCreateSuccess])

	//Dashboard Figures
	const activeUserMemberships = userMemberships
		?.filter((userMembership) => {
			return userMembership.membershipStatus === 'active'
		})
		.map((userMembership) => {
			return {
				...userMembership,
				totalMembershipPaymentsToOrg:
					userMembership.origin === 'membershipFlow'
						? userMembership.totalAmountPaid /
						  (1 + (userMembership.membershipProgram?.taxRate || 0))
						: userMembership.totalAmountPaid,
				cleanOrigin:
					userMembership.origin === 'membershipFlow'
						? 'Flow'
						: userMembership.origin === 'manual'
						? 'Manual'
						: 'Other',
			}
		})

	const collectedActiveMemberships = sum(
		userMemberships?.filter((userMembership) => {
			return userMembership.status !== 'Open'
		}),
		'totalAmountPaid'
	)
	const orgEarningsActiveMemberships = sum(
		userMemberships?.filter((userMembership) => {
			return userMembership.status === 'Paid in full'
		}),
		'basePrice'
	)

	const orgPayouts = org?.payouts ? sum(org?.payouts, 'amount') : 0
	const orgRemaining = orgEarningsActiveMemberships - (orgPayouts ?? 0)
	//End Dashboard Figures

	function sum(arr, key) {
		let res
		if (arr?.length) {
			res = arr.reduce((a, b) => a + (b[key] || 0), 0)
		}
		return res
	}

	let payoutsExport = payouts?.map(({ date, amount, notes }) => {
		const result = {
			date,
			amount,
			notes,
		}

		return result
	})

	const handleIssuePayoutClick = () => {
		setShowModal(true)
	}

	const handleCloseModal = () => {
		setErrorMessage('')
		setShowModal(false)
		setNewMember({
			fullName: '',
			email: '',
			phone: '',
			dob: '',
			tShirtSize: '',
			totalAmountPaid: '',
			paymentMethod: '',
			membershipStatus: 'active',
			origin: 'manual',
			org: org?._id,
		})
	}
	const [errorMessage, setErrorMessage] = useState(null)

	const validateForm = () => {
		// Basic form validations
		if (!newMember.membershipProgram) return 'Membership program is required'
		if (!newMember.fullName) return 'Full Name is required'
		if (!newMember.email) return 'Email is required'
		if (!newMember.totalAmountPaid) return 'Total Amount Paid is required'
		if (!newMember.paymentMethod) return 'Payment Method is required'

		// Check if the email already exists for the selected membership program
		const isDuplicateMember = userMemberships?.some(
			(userMembership) =>
				userMembership.user.email === newMember.email &&
				userMembership.membershipProgram._id === newMember.membershipProgram &&
				userMembership.membershipStatus === 'active'
		)

		if (isDuplicateMember) {
			return 'This email is already a member of the selected membership program'
		}

		return null // No validation errors
	}

	const handleSaveMember = () => {
		const error = validateForm()
		if (error) {
			setErrorMessage(error) // Set error message if validation fails
			return
		}

		newMember.org = org?._id
		// Dispatch action to save the new member
		dispatch(createUserMembership(newMember))
		handleCloseModal() // Close modal after saving
	}

	return (
		<>
			<OrgAdminNav />
			<Container className='my-4'>
				{/* {showSuccessMessage && (
					<Message variant='success'>Member successfully added</Message>
				)} */}
				{!userMemberships || org?.slug !== orgSlug ? (
					<Loader />
				) : userMemberships.length > 0 ? (
					<>
						<Row className='align-items-center'>
							<Col>
								<h1>Payouts</h1>
							</Col>
							<Col className='text-right'>
								{userCredInfo.isAdmin && (
									<Button
										className='my-3 mx-2'
										variant='light'
										onClick={handleIssuePayoutClick}
									>
										Issue Payout
									</Button>
								)}
								{payoutsExport && (
									<CSVLink
										data={payoutsExport}
										filename={`Payouts - ${org?.name}.csv`}
									>
										<Button className='my-3'>Export Payouts</Button>
									</CSVLink>
								)}
							</Col>
						</Row>
						<Row>
							<Col lg='4' md='4' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Earnings</Card.Header>
									<Card.Body>
										<Card.Title>
											$
											{orgEarningsActiveMemberships
												? orgEarningsActiveMemberships?.toFixed(2)
												: Number(0).toFixed(2)}
										</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							<Col lg='4' md='4' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Payouts Issued</Card.Header>
									<Card.Body>
										<Card.Title>${orgPayouts?.toFixed(2) ?? '0.00'}</Card.Title>
									</Card.Body>
								</Card>
							</Col>

							<Col lg='4' md='4' sm='12'>
								<Card className='my-2' bg='light'>
									<Card.Header>Total Org Payouts Remaining</Card.Header>
									<Card.Body>
										<Card.Title>
											$
											{orgRemaining
												? orgRemaining.toFixed(2)
												: Number(0).toFixed(2)}
										</Card.Title>
									</Card.Body>
								</Card>
							</Col>
						</Row>

						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th style={{ width: '25%' }}>DATE</th>
									<th style={{ width: '25%' }}>AMOUNT ISSUED</th>
									<th style={{ width: '50%' }}>NOTES</th>
								</tr>
							</thead>
							<tbody>
								{payouts.map((payout, index) => (
									<tr key={index}>
										<td>{payout?.date}</td>
										<td>${payout?.amount?.toFixed(2)}</td>
										<td>{payout?.notes}</td>
									</tr>
								))}
							</tbody>
						</Table>
						<Modal
							show={showModal}
							onHide={handleCloseModal}
							dialogClassName='custom-modal'
						>
							<Modal.Header closeButton>
								<Modal.Title>Issue Org Payout</Modal.Title>
							</Modal.Header>
							<Modal.Body className='modal-body-scrollable'>
								<Form>
									<Form.Group controlId='totalAmountPaid'>
										<Form.Label>Payout Amount ($)*</Form.Label>
										<Form.Control
											required
											type='number'
											placeholder='Enter payout amount'
											value={newMember.totalAmountPaid}
											onChange={(e) =>
												setNewMember({
													...newMember,
													totalAmountPaid: e.target.value,
												})
											}
										/>
									</Form.Group>
									<Form.Group controlId='notes'>
										<Form.Label>Notes</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter notes'
											value={newMember.fullName}
											onChange={(e) =>
												setNewMember({ ...newMember, fullName: e.target.value })
											}
										/>
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								{errorMessage && (
									<Col xs={12}>
										<Message variant='danger'>{errorMessage}</Message>
									</Col>
								)}
								<Row className='w-100'>
									<Col className='text-right'>
										<Button variant='secondary' onClick={handleCloseModal}>
											Close
										</Button>
										<Button
											variant='primary'
											//onClick={}
										>
											Issue Payout
										</Button>
									</Col>
								</Row>
							</Modal.Footer>
						</Modal>
					</>
				) : (
					<h2>No memberships have been purchased</h2>
				)}
			</Container>
		</>
	)
}

export default OrgManagePayoutsScreen
