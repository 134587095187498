import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'

const OrgAdminNav = () => {
	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	const orgSlug = org?.slug // Ensure safe access with optional chaining

	return (
		<Navbar
			style={{ backgroundColor: org?.primaryColor ?? '#cccccc' }}
			variant='dark'
			expand='sm'
			collapseOnSelect
			className='navbar-custom'
		>
			<Container>
				{org && (
					<a
						href={`https://go.idotours.com/org/${orgSlug}/manage`}
						style={{ color: '#000', fontSize: '1rem', textDecoration: 'none' }}
					>
						<Navbar.Brand>{org.name}</Navbar.Brand>
					</a>
				)}
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ml-auto'>
						<Nav.Link as={Link} to={`/org/${orgSlug}/manage`}>
							Members
						</Nav.Link>
						<Nav.Link as={Link} to={`/org/${orgSlug}/manage/payouts`}>
							Payouts
						</Nav.Link>
						<Nav.Link as={Link} to={`/org/${orgSlug}/manage/roles`}>
							Roles
						</Nav.Link>
						<Nav.Link as={Link} to={`/org/${orgSlug}/manage/experiences`}>
							Experiences
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default OrgAdminNav
