import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
	getExperienceDetails,
	updateExperience,
} from '../services/actions/experienceActions'
import { EXPERIENCE_UPDATE_RESET } from '../services/constants/experienceConstants'

const ExperienceListDestinationScreen = () => {
	const navigate = useNavigate()
	const { id: experienceId } = useParams()

	const dispatch = useDispatch()

	const [destination, setDestination] = useState([])

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!experience.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		} else {
			setDestination(experience.destination)
		}

		dispatch({ type: EXPERIENCE_UPDATE_RESET })

		if (!userInfo.isAdmin) {
			navigate('/login')
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		successUpdate,
		experience,
		experienceId,
		destination,
	])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			const newDestination = destination.filter((item) => {
				return item._id !== id
			})

			setDestination(newDestination)

			dispatch(
				updateExperience({
					_id: experienceId,
					destination: newDestination,
				})
			)
		}
	}

	const createDestinationItemHandler = () => {
		destination.push({
			sortOrder: 0,
			title: 'Title',
			description: 'Description',
			image: 'Image',
		})

		dispatch(
			updateExperience({
				_id: experienceId,
				destination: destination,
			})
		)
	}

	return (
		<>
			<Row className='align-items-center my-4'>
				<Col>
					<h1>Destination</h1>
				</Col>
				<Col className='text-right'>
					<Button onClick={createDestinationItemHandler}>
						<i className='fas fa-plus'></i> Create Destination Item
					</Button>
				</Col>
			</Row>
			{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
			{loading || loadingUpdate ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th>SORT ORDER</th>
							<th>TITLE</th>
							<th>DESC</th>
							<th>IMAGE</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{destination.map((item) => (
							<tr key={item._id}>
								<td>{item.sortOrder}</td>
								<td>{item.title}</td>
								<td>{item.description}</td>
								<td>{item.image}</td>

								<td>
									<NavLink
										to={`/admin/experience/${experience._id}/destination/${item._id}`}
									>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-edit'></i>
										</Button>
									</NavLink>
									<Button
										variant='danger'
										className='btn-sm'
										onClick={() => deleteHandler(item._id)}
									>
										<i className='fas fa-trash'></i>
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</>
	)
}

export default ExperienceListDestinationScreen
