import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, NavLink, useNavigate } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import SearchBox from './SearchBox'

const SubHeader = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const location = useLocation()
	const navigate = useNavigate()

	return (
		<>
			{userInfo && userInfo.isAdmin && (
				<Navbar
					style={{ backgroundColor: '#f38f62' }}
					variant='dark'
					expand='sm'
					collapseOnSelect
					className='navbar-custom'
				>
					<Container>
						<NavLink to='/admin/' style={{ color: '#000' }}>
							<Navbar.Brand>Admin</Navbar.Brand>
						</NavLink>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							{location.pathname.startsWith('/admin/userlist') && (
								<SearchBox navigate={navigate} />
							)}
							<Nav className='ml-auto'>
								<NavLink to='/admin/userlist' className='nav-link'>
									Users
								</NavLink>
								<NavLink to='/admin/experienceList' className='nav-link'>
									Experiences
								</NavLink>
								<NavLink to='/admin/org-list' className='nav-link'>
									Orgs
								</NavLink>
								<NavLink to='/admin/deallist' className='nav-link'>
									Deals
								</NavLink>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			)}
		</>
	)
}

export default SubHeader
