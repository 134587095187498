import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listDeals } from '../../services/actions/dealActions'

const DealListScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const dealList = useSelector((state) => state.dealList)
	const { loading, error, deals } = dealList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo.isAdmin) {
			navigate('/login')
		}
		dispatch(listDeals())
	}, [dispatch, navigate, userInfo])

	return (
		<Container className='my-3'>
			<Row className='align-items-center'>
				<Col>
					<h1>Deals</h1>
				</Col>
			</Row>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th>BRAND</th>
							<th>TITLE</th>
							<th>DESCRIPTION</th>
						</tr>
					</thead>
					<tbody>
						{deals.map((deal) => (
							<tr key={deal._id}>
								<td>{deal.brandName}</td>
								<td>
									{/* <Link to={`/org/${org.slug}/manage`}> */}
									{deal.dealTitle}
									{/* </Link> */}
								</td>
								<td>{deal.dealDescription}</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</Container>
	)
}

export default DealListScreen
