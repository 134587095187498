import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
	getExperienceDetails,
	updateExperience,
} from '../services/actions/experienceActions'
import { EXPERIENCE_UPDATE_RESET } from '../services/constants/experienceConstants'

const ExperienceEditItineraryScreen = () => {
	const navigate = useNavigate()
	const { id: experienceId, itinid: itineraryId } = useParams()

	const [uploading, setUploading] = useState(false)

	const [dayName, setDayName] = useState('')
	const [sortOrder, setSortOrder] = useState(0)
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [image, setImage] = useState('')

	const dispatch = useDispatch()

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo.isAdmin) {
			navigate('/')
		} else if (successUpdate) {
			dispatch({ type: EXPERIENCE_UPDATE_RESET })
			dispatch(getExperienceDetails(experienceId))
			navigate(`/admin/experience/${experienceId}/itinerary`)
		} else {
			if (!experience.name || experience._id !== experienceId) {
				dispatch(getExperienceDetails(experienceId))
			} else {
				const itineraryItemIndex = experience.itinerary.findIndex(
					(item) => item._id === itineraryId
				)
				setDayName(experience.itinerary[itineraryItemIndex].dayName)
				setSortOrder(experience.itinerary[itineraryItemIndex].sortOrder)
				setTitle(experience.itinerary[itineraryItemIndex].title)
				setDescription(experience.itinerary[itineraryItemIndex].description)
				setImage(experience.itinerary[itineraryItemIndex].image)
			}
		}
	}, [
		experience,
		experienceId,
		dispatch,
		navigate,
		successUpdate,
		itineraryId,
		userInfo.isAdmin,
	])

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('image', file)
		setUploading(true)

		try {
			const config = {
				headers: {
					'Content-type': 'multipart/form/data',
				},
			}
			const { data } = await axios.post('/api/upload', formData, config)

			setImage(data)
			setUploading(false)
		} catch (error) {
			console.error.apply(error)
			setUploading(false)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()

		const newItinerary = experience.itinerary.map((item) =>
			item._id === itineraryId
				? {
						...item,
						dayName: dayName,
						sortOrder: sortOrder,
						title: title,
						description: description,
						image: image,
				  }
				: item
		)
		dispatch(
			updateExperience({
				_id: experienceId,
				itinerary: newItinerary,
			})
		)
	}

	return (
		<>
			<Container>
				<Link
					to={`/admin/experience/${experienceId}/itinerary`}
					className='btn btn-light my-3'
				>
					Go Back
				</Link>
				<FormContainer>
					<h1>Edit Itinerary Item</h1>
					{loadingUpdate && <Loader />}
					{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
					{loading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error}</Message>
					) : (
						<Form onSubmit={submitHandler}>
							<Form.Group controlId='dayName'>
								<Form.Label>Day</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter day'
									value={dayName}
									onChange={(e) => setDayName(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='sortOrder'>
								<Form.Label>Sort Order</Form.Label>
								<Form.Control
									type='number'
									placeholder='Enter sort order'
									value={sortOrder}
									onChange={(e) => setSortOrder(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='title'>
								<Form.Label>Title</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter title'
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='description'>
								<Form.Label>Description</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter description'
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='image'>
								<Form.Label>Image</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter image url'
									value={image}
									onChange={(e) => setImage(e.target.value)}
								></Form.Control>
								<Form.File
									id='image-file'
									label='Choose File'
									custom
									onChange={uploadFileHandler}
								></Form.File>
								{uploading && <Loader />}
							</Form.Group>

							<Button type='submit' variant='primary'>
								Update
							</Button>
						</Form>
					)}
				</FormContainer>
			</Container>
		</>
	)
}

export default ExperienceEditItineraryScreen
