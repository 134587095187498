import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
	getUserDetails,
	updateUserProfile,
} from '../services/actions/userActions'
import { listMyOrders } from '../services/actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../services/constants/userConstants'

const ProfileScreen = () => {
	const navigate = useNavigate()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [message, setMessage] = useState(null)
	const [saveError, setSaveError] = useState(null)

	const dispatch = useDispatch()

	const userDetails = useSelector((state) => state.userDetails)
	const { loading, error, user } = userDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
	const { success, error: updateError } = userUpdateProfile

	useEffect(() => {
		setSaveError(updateError)
	}, [navigate, userUpdateProfile])

	useEffect(() => {
		if (!userInfo) {
			navigate('/login')
		} else {
			if (!user || !user.name) {
				dispatch(getUserDetails('profile'))
			} else {
				setName(user.name)
				setEmail(user.email)
				setPhone(user.phone)
			}
		}
	}, [dispatch, navigate, userInfo, user])

	useEffect(() => {
		dispatch({ type: USER_UPDATE_PROFILE_RESET })
	}, [])

	const validatePassword = (password, confirmPassword) => {
		if (password !== confirmPassword) {
			const error = 'Passwords do not match'
			return error
		}
		if (password.length < 8) {
			const error = 'Password must be at least 8 characters long.'
			return error
		}
		if (!/[A-Z]/.test(password)) {
			const error = 'Password must include at least one uppercase letter.'
			return error
		}
		if (!/[a-z]/.test(password)) {
			const error = 'Password must include at least one lowercase letter.'
			return error
		}
		if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
			const error = 'Password must include at least one special character.'
			return error
		}
		if (/\s/.test(password)) {
			const error = 'Password must not contain spaces.'
			return error
		}
		const error = false
		return error
	}

	const submitHandler = (e) => {
		e.preventDefault()
		setMessage('')
		setSaveError('')
		const passwordErrors = validatePassword(password, confirmPassword)
		if (passwordErrors) {
			setMessage(passwordErrors)
			return
		} else {
			dispatch(
				updateUserProfile({ id: user._id, name, email, phone, password })
			)
		}
	}

	return (
		<Container>
			<Row className='my-4'>
				<Col md={3}>
					<h2>User Profile</h2>
					{message && <Message variant='danger'>{message}</Message>}
					{saveError && <Message variant='danger'>{saveError}</Message>}
					{success && <Message variant='success'>Profile Updated</Message>}
					{loading && <Loader />}
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='fullName'>
							<Form.Label>Full Name</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter full name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='email'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='phone'>
							<Form.Label>Phone</Form.Label>
							<Form.Control
								type='tel'
								placeholder='Enter phone'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='password'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Enter password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								autoComplete='new-password'
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='confirmPassword'>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Confirm password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								autoComplete='new-password'
							></Form.Control>
						</Form.Group>

						<Button type='submit' variant='primary'>
							Update
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default ProfileScreen
