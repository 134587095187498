import { Button } from 'react-bootstrap'

const OrgExperience = ({ experience }) => {
	const experienceUrl =
		experience.url?.trim() || `/experiences/${experience._id}`
	const isExternal = experience.url && experience.url.startsWith('http')

	return (
		<>
			{experience && (
				<li
					className='cards__item m-0'
					style={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '372px',
						boxShadow: '0px 0px 15px #f5f5f5',
						maxWidth: '100%',
						flex: '1 1 auto',
						cursor: 'pointer',
					}}
					onClick={() => {
						if (isExternal) {
							window.open(experienceUrl, '_blank', 'noopener,noreferrer')
						} else {
							window.location.href = experienceUrl
						}
					}}
				>
					<div style={{ flex: '1' }}>
						<figure
							className='cards__item__pic-wrap-no-box'
							data-category={experience.type}
						>
							<img
								className='cards__item__img'
								alt='Travel Pic'
								src={experience.image}
							/>
						</figure>
						<div className='cards__item__info'>
							<h5 className='cards__item__title mb-2'>{experience.name}</h5>
						</div>
					</div>
					<Button
						className='btn-block'
						type='button'
						style={{ marginTop: 'auto' }}
					>
						View Experience
					</Button>
				</li>
			)}
		</>
	)
}

export default OrgExperience
