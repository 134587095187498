import { Button } from 'react-bootstrap'

const OrgDeal = ({ deal }) => {
	const dealUrl = deal.url ?? `/experiences/${deal._id}`
	const isExternal = deal.url && deal.url.startsWith('http')

	return (
		<>
			{deal && (
				<li
					className='cards__item m-0'
					style={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '372px',
						boxShadow: '0px 0px 15px #f5f5f5',
						maxWidth: '100%',
						flex: '1 1 auto',
						cursor: 'pointer',
					}}
					onClick={() => {
						window.open(dealUrl, '_blank', 'noopener,noreferrer')
					}}
				>
					<div style={{ flex: '1', backgroundColor: '#fff' }}>
						<figure
							className='cards__item__pic-wrap-no-box'
							data-category={deal.type}
						>
							<img
								className='cards__item__img'
								alt='Picture of deal'
								src={deal.image}
							/>
						</figure>
						<div className='cards__item__info'>
							<h5 className='cards__item__title mb-2'>{deal.brandName}</h5>
							<h6 className='mb-2'>{deal.dealTitle}</h6>
						</div>
					</div>
					<Button
						className='btn-block'
						type='button'
						style={{ marginTop: 'auto' }}
					>
						View Deal
					</Button>
				</li>
			)}
		</>
	)
}

export default OrgDeal
