import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CheckoutSteps = ({
	review,
	travelerInfo,
	tripOptions,
	paymentStructure,
	placeOrder,
}) => {
	const flow = useSelector((state) => state.flow)
	const {
		flowItem,
		flowItem: { experience: experienceId },
	} = flow

	const hasTripOptions =
		flow?.flowItem?.tripOption?.filter(
			(option) => option.status === 'Available'
		).length > 0
	const hasDeposit = flowItem?.depositOption

	return (
		<Nav className='justify-content-center mb-4'>
			<Nav.Item className='mr-2 ml-2'>
				{review ? (
					<NavLink to={`/${experienceId}/review`}>
						<Nav.Link>Review</Nav.Link>
					</NavLink>
				) : (
					<Nav.Link disabled>Review</Nav.Link>
				)}
			</Nav.Item>

			<Nav.Item className='mr-2 ml-2'>
				{travelerInfo ? (
					<NavLink to='/travelerinfo'>
						<Nav.Link>Traveler Info</Nav.Link>
					</NavLink>
				) : (
					<Nav.Link disabled>Traveler Info</Nav.Link>
				)}
			</Nav.Item>

			{hasTripOptions && (
				<Nav.Item className='mr-2 ml-2'>
					{tripOptions ? (
						<NavLink to={`/${experienceId}/options`}>
							<Nav.Link>Options</Nav.Link>
						</NavLink>
					) : (
						<Nav.Link disabled>Options</Nav.Link>
					)}
				</Nav.Item>
			)}

			{hasDeposit && (
				<Nav.Item className='mr-2 ml-2'>
					{paymentStructure ? (
						<NavLink to='/payment'>
							<Nav.Link>Payment Structure</Nav.Link>
						</NavLink>
					) : (
						<Nav.Link disabled>Payment Structure</Nav.Link>
					)}
				</Nav.Item>
			)}

			<Nav.Item className='mr-2 ml-2'>
				{placeOrder ? (
					<Nav.Link>Place Order</Nav.Link>
				) : (
					<Nav.Link disabled>Place Order</Nav.Link>
				)}
			</Nav.Item>
		</Nav>
	)
}

export default CheckoutSteps
