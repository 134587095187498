import React, { useState, useEffect } from 'react'
import { Form, Button, Row, ListGroup, Container } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import {
	getUserDetails,
	updateUserProfile,
} from '../services/actions/userActions'
import { createOrder } from '../services/actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../services/constants/userConstants'

const TravelerInfoScreen = () => {
	const navigate = useNavigate()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [dateOfBirth, setDateOfBirth] = useState('')
	const [activityStyle, setActivityStyle] = useState('')
	const [shoeSize, setShoeSize] = useState('Select shoe size...')
	const [height, setHeight] = useState('')
	const [weight, setWeight] = useState('')
	const [shirtSize, setShirtSize] = useState('Select a size...')
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
	const { success } = userUpdateProfile

	const orderCreate = useSelector((state) => state.orderCreate)
	const { order: createdOrder, success: successOrder } = orderCreate

	const flow = useSelector((state) => state.flow)
	const {
		flowItem,
		flowItem: { experience },
	} = flow

	const userDetails = useSelector((state) => state.userDetails)
	const { user } = userDetails

	useEffect(() => {
		if (!userInfo) {
			navigate('/login')
		} else {
			if (!user?._id || success) {
				dispatch({ type: USER_UPDATE_PROFILE_RESET })
				dispatch(getUserDetails('profile'))
			} else {
				setName(user.name)
				setEmail(user.email)
				setPhone(user.phone)
				setDateOfBirth(user.dateOfBirth)
				setActivityStyle(user.activityStyle)
				setShoeSize(user.shoeSize)
				setHeight(user.height)
				setWeight(user.weight)
				setShirtSize(user.shirtSize)
			}
		}
		if (successOrder) {
			navigate(`/order/${createdOrder._id}`)
		}
		if (user) {
			const { orders: userOrders } = user
			const userExperienceOrder = userOrders
				? userOrders.filter((e) => {
						return experience.includes(e.experience)
				  })[0] ?? []
				: []
			if (userExperienceOrder.experience === experience) {
				navigate(`/order/${userExperienceOrder._id}`)
			}
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		user,
		success,
		successOrder,
		createdOrder,
		experience,
	])

	//Date Logic
	const currentYear = new Date().getFullYear()

	const startYear = new Date(flowItem?.experienceStartDate).getFullYear()

	const startMonth =
		flowItem &&
		new Date(flowItem?.experienceStartDate).toLocaleString('default', {
			month: 'short',
		})

	const startDay = new Date(flowItem?.experienceStartDate).getDate()

	const endYear = new Date(flowItem?.experienceEndDate).getFullYear()

	const endMonth =
		flowItem &&
		new Date(flowItem?.experienceEndDate).toLocaleString('default', {
			month: 'short',
		})

	const endDay = flowItem && new Date(flowItem?.experienceEndDate).getDate()

	const startDate =
		flowItem &&
		startMonth +
			' ' +
			startDay +
			(startYear !== endYear ? ', ' + startYear : '')

	const endDate =
		(startMonth === endMonth ? endDay : endMonth + ' ' + endDay) +
		(endYear !== startYear || endYear !== currentYear ? ', ' + endYear : '')

	const fullDate = flowItem && startDate + ' - ' + endDate
	//End Date Logic

	const submitHandler = (e) => {
		e.preventDefault()
		updateUser()
		if (
			flow?.flowItem?.tripOption?.filter(
				(option) => option.status === 'Available'
			).length > 0
		) {
			navigate(`/${experience}/options`)
		} else if (flow?.flowItem?.depositOption) {
			navigate('/payment')
		} else {
			dispatch(
				createOrder({
					experience: experience,
					paymentStructure: 'Pay in full',
					firstPaymentPrice: flow.orderInfo.totalOrderAmount,
					taxPrice: flow.orderInfo.totalTaxesAmount,
					totalPrice: flow.orderInfo.totalOrderAmount,
					totalAmountRemaining: flow.orderInfo.totalOrderAmount,
					totalAmountPaid: 0,
					finalPaymentDueDate: flow.orderInfo.finalPaymentDueDate,
					addOns: flow.orderInfo.addOns,
					addOnsPrice: flow.orderInfo.addOnsPrice,
				})
			)
		}
	}

	const updateUser = () => {
		dispatch(
			updateUserProfile({
				id: user._id,
				name: name,
				email: email,
				phone: phone,
				dateOfBirth: dateOfBirth,
				activityStyle: activityStyle,
				shoeSize: shoeSize,
				height: height,
				weight: weight,
				shirtSize: shirtSize,
			})
		)
	}

	return (
		<Container>
			{Object.keys(flowItem).length === 0 ? (
				<Container>
					You have no active bookings <Link to='/'>Go Back</Link>
				</Container>
			) : (
				<div className='my-4'>
					<Container
						className='my-4'
						style={{
							boxShadow: '0 0 10px #b0b0b0',
						}}
					>
						<ListGroup variant='flush'>
							<ListGroup.Item className='p-2 mt-4'>
								<h1 className='text-center'>{flowItem.name}</h1>
								<h5 className='text-center'>
									{' '}
									{`${flowItem.locationName} | ${fullDate}`}
								</h5>
							</ListGroup.Item>
						</ListGroup>

						<Row
							className='justify-content-center'
							style={{ backgroundColor: '#F3F3F3' }}
						>
							<CheckoutSteps review travelerInfo />
						</Row>
					</Container>

					<FormContainer>
						<h1>Traveler Information</h1>
						<Form onSubmit={submitHandler}>
							<Form.Group controlId='name'>
								<Form.Label>Full Name</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter full name'
									value={name}
									onChange={(e) => setName(e.target.value)}
									required={true}
									disabled={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='email'>
								<Form.Label>Email Address</Form.Label>
								<Form.Control
									type='email'
									placeholder='Enter email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required={true}
									disabled={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='phone'>
								<Form.Label>Phone</Form.Label>
								<Form.Control
									type='tel'
									placeholder='Enter phone'
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									required={true}
								></Form.Control>
							</Form.Group>

							<Form.Group controlId='dateOfBirth'>
								<Form.Label>Date of Birth</Form.Label>
								<Form.Control
									type='date'
									placeholder='Enter date of birth'
									value={dateOfBirth && dateOfBirth.substring(0, 10)}
									onChange={(e) => setDateOfBirth(e.target.value)}
									required={true}
								></Form.Control>
							</Form.Group>
							<br />
							{flowItem.sizingOption ? (
								<>
									<h2>Sizing</h2>
									<h5>Some activities require sizing information</h5>

									<Form.Group controlId='shoeSize'>
										<Form.Label>Shoe size (US Men's)</Form.Label>
										<Form.Control
											as='select'
											type='text'
											value={shoeSize}
											required
											onChange={(e) => setShoeSize(e.target.value)}
										>
											<option disabled>Select shoe size...</option>
											<option>6</option>
											<option>6.5</option>
											<option>7</option>
											<option>7.5</option>
											<option>8</option>
											<option>8.5</option>
											<option>9</option>
											<option>9.5</option>
											<option>10</option>
											<option>10.5</option>
											<option>11</option>
											<option>11.5</option>
											<option>12</option>
											<option>13</option>
											<option>14</option>
											<option>15</option>
											<option>16</option>
										</Form.Control>
									</Form.Group>

									<Form.Group controlId='height'>
										<Form.Label>Height (Inches)</Form.Label>
										<Form.Control
											type='number'
											placeholder='Enter height in inches'
											value={height}
											onChange={(e) => setHeight(e.target.value)}
											required
										></Form.Control>
									</Form.Group>

									<Form.Group controlId='weight'>
										<Form.Label>Weight (Pounds)</Form.Label>
										<Form.Control
											type='number'
											placeholder='Enter weight in pounds'
											value={weight}
											onChange={(e) => setWeight(e.target.value)}
											required
										></Form.Control>
									</Form.Group>

									<Form.Group controlId='shirtSize'>
										<Form.Label>T-Shirt Size</Form.Label>
										<Form.Control
											as='select'
											type='text'
											value={shirtSize}
											required
											onChange={(e) => setShirtSize(e.target.value)}
										>
											<option disabled>Select a size...</option>
											<option>Adult XXL</option>
											<option>Adult XL</option>
											<option>Adult L</option>
											<option>Adult M</option>
											<option>Adult S</option>
										</Form.Control>
									</Form.Group>
								</>
							) : (
								''
							)}

							<Row className='mt-3 p-2'>
								<NavLink to={`/${experience}/review`}>
									<Button className='mr-1 btn-secondary' type='button'>
										Back
									</Button>
								</NavLink>

								<Button type='submit' variant='primary'>
									Continue
								</Button>
							</Row>
						</Form>
					</FormContainer>
				</div>
			)}
		</Container>
	)
}

export default TravelerInfoScreen
