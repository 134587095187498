import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import DateCard from '../components/DateCard/DateCard'
import {
	listExperiences,
	deleteExperience,
	createExperience,
	copyExperience,
} from '../services/actions/experienceActions'
import { EXPERIENCE_CREATE_RESET } from '../services/constants/experienceConstants'

const ExperienceListScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const experienceList = useSelector((state) => state.experienceList)
	const { loading, error, experiences } = experienceList

	const experienceDelete = useSelector((state) => state.experienceDelete)
	const {
		loading: loadingDelete,
		success: successDelete,
		error: errorDelete,
	} = experienceDelete

	const experienceCreate = useSelector((state) => state.experienceCreate)
	const {
		loading: loadingCreate,
		success: successCreate,
		error: errorCreate,
		experience: createdExperience,
	} = experienceCreate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		dispatch({ type: EXPERIENCE_CREATE_RESET })

		if (!userInfo.isAdmin) {
			navigate('/login')
		}

		if (successCreate) {
			navigate(`/admin/experience/${createdExperience._id}/details`)
		} else {
			dispatch(listExperiences())
		}
	}, [
		dispatch,
		navigate,
		userInfo,
		successDelete,
		successCreate,
		createdExperience,
	])

	const deleteHandler = (id) => {
		if (
			window.confirm('Are you sure you would like to delete this experience?')
		) {
			dispatch(deleteExperience(id))
		}
	}

	const createExperienceHandler = () => {
		dispatch(createExperience())
	}

	const copyExperienceHandler = (id) => {
		if (
			window.confirm(
				'Are you sure you would like to make a copy of this experience?'
			)
		) {
			dispatch(copyExperience(id))
		}
	}

	return (
		<Container>
			<Row className='align-items-center'>
				<Col>
					<h1>Experiences</h1>
				</Col>
				<Col className='text-right'>
					<Button className='my-3' onClick={createExperienceHandler}>
						<i className='fas fa-plus'></i> Create Experience
					</Button>
				</Col>
			</Row>
			{loadingDelete && <Loader />}
			{errorDelete && <Message variant='danger'>{errorDelete}</Message>}
			{loadingCreate && <Loader />}
			{errorCreate && <Message variant='danger'>{errorCreate}</Message>}
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th style={{ verticalAlign: 'middle' }}>EXPERIENCE</th>
							<th style={{ verticalAlign: 'middle' }}>PRICE</th>
							<th style={{ verticalAlign: 'middle' }}>TYPE</th>
							<th style={{ verticalAlign: 'middle' }}>BRAND</th>
							<th style={{ verticalAlign: 'middle', minWidth: '200px' }}></th>
						</tr>
					</thead>
					<tbody>
						{experiences.map((experience) => (
							<tr key={experience._id}>
								<td style={{ verticalAlign: 'middle' }}>
									<Row className='m-1 align-items-center'>
										<DateCard date={experience.experienceStartDate} />
										<Link
											className='ml-3'
											to={`/admin/experience/${experience._id}/details`}
										>
											{experience.name}
										</Link>
									</Row>
								</td>
								<td style={{ verticalAlign: 'middle' }}>
									${experience.fullPriceWithTax.toFixed(2)}
								</td>
								<td style={{ verticalAlign: 'middle' }}>{experience.type}</td>
								<td style={{ verticalAlign: 'middle' }}>{experience.brand}</td>
								<td style={{ verticalAlign: 'middle', minWidth: '200px' }}>
									<NavLink
										to='route'
										target='_blank'
										onClick={(event) => {
											event.preventDefault()
											window.open(`../experiences/${experience._id}`)
										}}
									>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-external-link-square-alt fa-lg'></i>
										</Button>
									</NavLink>
									<NavLink to={`/admin/experience/${experience._id}/details`}>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-pen-square fa-lg'></i>
										</Button>
									</NavLink>
									<Button
										variant='light'
										className='btn-sm'
										onClick={() => copyExperienceHandler(experience._id)}
									>
										<i className='fas fa-copy fa-lg'></i>
									</Button>
									<Button
										variant='danger'
										className='btn-sm'
										onClick={() => deleteHandler(experience._id)}
									>
										<i className='fas fa-trash fa-lg'></i>
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</Container>
	)
}

export default ExperienceListScreen
