import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const SearchBox = () => {
	const navigate = useNavigate()
	const [keyword, setKeyword] = useState('')

	const submitHandler = (e) => {
		e.preventDefault()
		if (keyword.trim()) {
			navigate(`/admin/userlist/search/${keyword}`)
		} else {
			navigate('/admin/userlist')
		}
	}

	return (
		<Form onSubmit={submitHandler} inline>
			<Form.Control
				type='text'
				name='q'
				onChange={(e) => setKeyword(e.target.value)}
				placeholder='Search...'
				className='mr-sm-2 ml-sm-5'
			></Form.Control>
			<Button type='submit' className='p-2'>
				Search
			</Button>
		</Form>
	)
}

export default SearchBox
