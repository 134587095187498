import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, ListGroup, Container, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import MembershipOptionCard from '../../../../components/MembershipOptionCard/MembershipOptionCard'
import MembershipFlowSteps from '../../../../components/MembershipFlowSteps/MembershipFlowSteps'
import Message from '../../../../components/Message'
import { getOrgDetails } from '../../../../services/actions/orgActions'
import { setMembershipFlow } from '../../../../services/actions/membershipflowActions'

const MembershipOptionsScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { slug: orgSlug } = useParams()
	const [error, setError] = useState(null)

	// Org details from state
	const orgDetails = useSelector((state) => state.orgDetails)
	const { loading, error: orgDetailsError, org } = orgDetails

	// MembershipFlow from state
	const membershipFlow = useSelector((state) => state.membershipFlow)

	useEffect(() => {
		if (!Object.keys(org).length) {
			dispatch(getOrgDetails(orgSlug))
		}
	}, [org, orgSlug, dispatch])

	const membershipPrograms = org?.membershipPrograms?.filter(
		(option) => option.status === 'Active'
	)

	const submitHandler = (e) => {
		if (!!membershipFlow?.flowMembershipProgram) {
			navigate(`/login?redirect=org/${orgSlug}/member-info`)
		} else setError('No membership selected.')
	}

	const backHandler = (e) => {
		navigate(`/org/${orgSlug}`)
	}

	const handleSetMembershipFlow = (membershipProgramId, orgSlug) => {
		if (membershipProgramId !== '') {
			setError('')
		}
		dispatch(setMembershipFlow(membershipProgramId, orgSlug))
	}

	return (
		<Container>
			<div className='my-4'>
				<Container
					className='my-4'
					style={{
						boxShadow: '0 0 10px #b0b0b0',
					}}
				>
					<ListGroup variant='flush'>
						<ListGroup.Item className='p-2 mt-4'>
							<h1 className='text-center'>{org.name}</h1>
							<h5 className='text-center'> {`${org.locationName}`}</h5>
						</ListGroup.Item>
					</ListGroup>

					<Row
						className='justify-content-center'
						style={{ backgroundColor: '#F3F3F3' }}
					>
						<MembershipFlowSteps org={org} membershipOptions />
					</Row>
				</Container>
				<Row>
					<Col md={12}>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Membership Options</h2>
								Please select a membership.
							</ListGroup.Item>
						</ListGroup>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						{membershipPrograms?.map((program) => (
							<MembershipOptionCard
								key={program._id}
								program={program}
								org={orgSlug}
								handleSetMembershipFlow={handleSetMembershipFlow}
							/>
						))}
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={8}>
						{error && <Message variant='danger'>{error}</Message>}
					</Col>
				</Row>
				<Row className='mt-3 p-2'>
					<Button
						className='mr-1 btn-secondary'
						type='button'
						onClick={backHandler}
					>
						Back
					</Button>

					<Button className='' type='button' onClick={submitHandler}>
						Continue
					</Button>
				</Row>
			</div>
		</Container>
	)
}

export default MembershipOptionsScreen
