import React, { useEffect } from 'react'
import { Row, ListGroup, Container, Col, Button, Card } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import OptionCard from '../components/OrderOptions/OptionCard'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../services/actions/orderActions'
import { saveOrderInfo } from '../services/actions/flowActions'

const OrderOptionsScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const flow = useSelector((state) => state.flow)

	const {
		flowItem: experience,
		flowItem: { tripOption: allTripOptions },
	} = flow

	const tripOptions = allTripOptions.filter(
		(option) => option.status === 'Available'
	)

	const orderCreate = useSelector((state) => state.orderCreate)
	const { order: createdOrder, success: successOrder } = orderCreate

	//Date Logic
	const currentYear = new Date().getFullYear()

	const startYear = new Date(experience?.experienceStartDate).getFullYear()

	const startMonth =
		experience &&
		new Date(experience?.experienceStartDate).toLocaleString('default', {
			month: 'short',
		})

	const startDay = new Date(experience?.experienceStartDate).getDate()

	const endYear = new Date(experience?.experienceEndDate).getFullYear()

	const endMonth =
		experience &&
		new Date(experience?.experienceEndDate).toLocaleString('default', {
			month: 'short',
		})

	const endDay = experience && new Date(experience?.experienceEndDate).getDate()

	const startDate =
		experience &&
		startMonth +
			' ' +
			startDay +
			(startYear !== endYear ? ', ' + startYear : '')

	const endDate =
		(startMonth === endMonth ? endDay : endMonth + ' ' + endDay) +
		(endYear !== startYear || endYear !== currentYear ? ', ' + endYear : '')

	const fullDate = experience && startDate + ' - ' + endDate
	//End Date Logic

	useEffect(() => {
		if (flow.flowItem.tripOption.length === 0) {
			submitHandler()
		}
		if (successOrder) {
			navigate(`/order/${createdOrder._id}`)
		}
	}, [dispatch, navigate, successOrder, createdOrder])

	const submitHandler = (e) => {
		e?.preventDefault()
		flow.flowItem.depositOption ? paymentStage(e) : placeOrderHandler(e)
	}

	const paymentStage = (e) => {
		e?.preventDefault()
		navigate('/payment')
	}

	const placeOrderHandler = (e) => {
		e?.preventDefault()
		dispatch(
			createOrder({
				experience: experience.experience,
				paymentStructure: 'Pay in full',
				firstPaymentPrice: flow.orderInfo.totalOrderAmount,
				taxPrice: flow.orderInfo.totalTaxesAmount,
				totalPrice: flow.orderInfo.totalOrderAmount,
				totalAmountRemaining: flow.orderInfo.totalOrderAmount,
				totalAmountPaid: 0,
				finalPaymentDueDate: flow.orderInfo.finalPaymentDueDate,
				addOns: flow.orderInfo.addOns,
				addOnsPrice: flow.orderInfo.addOnsPrice,
			})
		)
	}

	const addTripOption = (tripOption, size) => {
		flow.orderInfo.addOns.push({
			tripOptionId: tripOption.tripOptionId,
			title: tripOption.title,
			price: tripOption.price,
			size: size,
		})

		flow.orderInfo.addOnsPrice = parseFloat(
			(
				parseFloat(flow.orderInfo.addOnsPrice) + parseFloat(tripOption.price)
			).toFixed(2)
		)

		flow.orderInfo.addOnsTaxes = parseFloat(
			(
				parseFloat(flow.orderInfo.addOnsTaxes) + parseFloat(tripOption.taxPrice)
			).toFixed(2)
		)

		flow.orderInfo.totalTaxesAmount = parseFloat(
			(
				parseFloat(flow.orderInfo.experienceTaxes) +
				parseFloat(flow.orderInfo.addOnsTaxes)
			).toFixed(2)
		)

		flow.orderInfo.totalOrderAmount = parseFloat(
			(
				parseFloat(flow.orderInfo.experiencePrice) +
				parseFloat(flow.orderInfo.addOnsPrice) +
				parseFloat(flow.orderInfo.totalTaxesAmount)
			).toFixed(2)
		)

		dispatch(saveOrderInfo(flow.orderInfo))
	}

	const removeTripOption = (tripOption) => {
		const updatedAddOns = flow.orderInfo.addOns.filter((addOn) => {
			return addOn.tripOptionId !== tripOption.tripOptionId
		})

		flow.orderInfo.addOnsTaxes = parseFloat(
			(
				parseFloat(flow.orderInfo.addOnsTaxes) - parseFloat(tripOption.taxPrice)
			).toFixed(2)
		)

		flow.orderInfo.addOns = updatedAddOns
		flow.orderInfo.totalOrderAmount = parseFloat(
			(
				parseFloat(flow.orderInfo.totalOrderAmount) -
				parseFloat(tripOption.price) -
				parseFloat(tripOption.taxPrice)
			).toFixed(2)
		)

		flow.orderInfo.addOnsPrice = parseFloat(
			(flow.orderInfo.addOnsPrice - tripOption.price).toFixed(2)
		)

		flow.orderInfo.totalTaxesAmount = parseFloat(
			(flow.orderInfo.totalTaxesAmount - tripOption.taxPrice).toFixed(2)
		)

		dispatch(saveOrderInfo(flow.orderInfo))
	}

	return (
		<Container>
			{Object.keys(experience).length === 0 ? (
				<Container>
					You have no active bookings <Link to='/'>Go Back</Link>
				</Container>
			) : (
				<div className='my-4'>
					<Container
						className='my-4'
						style={{
							boxShadow: '0 0 10px #b0b0b0',
						}}
					>
						<ListGroup variant='flush'>
							<ListGroup.Item className='p-2 mt-4'>
								<h1 className='text-center'>{experience.name}</h1>
								<h5 className='text-center'>
									{' '}
									{`${experience.locationName} | ${fullDate}`}
								</h5>
							</ListGroup.Item>
						</ListGroup>

						<Row
							className='justify-content-center'
							style={{ backgroundColor: '#F3F3F3' }}
						>
							<CheckoutSteps review travelerInfo tripOptions />
						</Row>
					</Container>
					<Row>
						<Col md={12}>
							<ListGroup variant='flush'>
								<ListGroup.Item>
									<h2>Trip Options and Add-ons</h2>
									Select from the options below to add them to your trip.
								</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							{tripOptions.map((tripOption) => (
								<OptionCard
									key={tripOption._id}
									tripOptionId={tripOption._id}
									title={tripOption.title}
									desc={tripOption.description}
									src={tripOption.image}
									taxPrice={(
										tripOption.fullPrice * flow.flowItem.taxRate
									).toFixed(2)}
									price={tripOption.fullPrice}
									addTripOption={addTripOption}
									removeTripOption={removeTripOption}
									showSizing={tripOption.showSizing}
								/>
							))}
						</Col>
						<Col md={4}>
							<Card>
								<ListGroup>
									<ListGroup.Item>
										<h2>Trip Summary</h2>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											<Col>Experience Price</Col>
											<Col>${flow.orderInfo.experiencePrice.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											<Col>Add-Ons</Col>
											<Col>
												$
												{flow.orderInfo.addOnsPrice
													? flow.orderInfo.addOnsPrice
													: 0.0}
											</Col>
										</Row>
									</ListGroup.Item>
									{!!flow.orderInfo?.totalTaxesAmount && (
										<ListGroup.Item>
											<Row>
												<Col>Taxes and Fees</Col>
												<Col>${flow.orderInfo.totalTaxesAmount.toFixed(2)}</Col>
											</Row>
										</ListGroup.Item>
									)}
									<ListGroup.Item>
										<Row>
											<Col>Total</Col>

											<Col>
												$
												{parseFloat(flow.orderInfo.totalOrderAmount).toFixed(2)}
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											<Col>
												<Button
													onClick={submitHandler}
													className='btn-block'
													type='button'
												>
													Continue to Checkout
												</Button>
											</Col>
										</Row>
									</ListGroup.Item>
								</ListGroup>
							</Card>
						</Col>
					</Row>
					<Row className='mt-3 p-2'>
						<NavLink to={`/travelerinfo`}>
							<Button className='mr-1 btn-secondary' type='button'>
								Back
							</Button>
						</NavLink>
						<Button className='' type='button' onClick={submitHandler}>
							Continue
						</Button>
					</Row>
				</div>
			)}
		</Container>
	)
}

export default OrderOptionsScreen
