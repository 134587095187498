import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { logout } from './services/actions/userActions'
import AuthVerify from './services/utils/AuthVerify'

import Header from './components/Header'
import SubHeader from './components/SubHeader'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ExperienceScreen from './screens/ExperienceScreen/ExperienceScreen'
import ReviewScreen from './screens/ReviewScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import TravelerInfoScreen from './screens/TravelerInfoScreen'
import OrderOptionsScreen from './screens/OrderOptionsScreen'
import PaymentScreen from './screens/PaymentScreen'
import OrderScreen from './screens/OrderScreen'
import OrderListScreen from './screens/OrderListScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ExperienceListScreen from './screens/ExperienceListScreen'
import ExperienceEditDetailsScreen from './screens/ExperienceEditDetailsScreen'
import ExperienceEditAccScreen from './screens/ExperienceEditAccScreen'
import ExperienceEditItineraryScreen from './screens/ExperienceEditItineraryScreen'
import ExperienceListItineraryScreen from './screens/ExperienceListItineraryScreen'
import ExperienceListOrgsScreen from './screens/ExperienceListOrgsScreen/ExperienceListOrgsScreen'
import ExperienceEditTripOptionScreen from './screens/TripOption/ExperienceEditTripOptionScreen'
import ExperienceListTripOptionScreen from './screens/TripOption/ExperienceListTripOptionScreen'
import ExperienceEditDestinationScreen from './screens/ExperienceEditDestinationScreen'
import ExperienceListDestinationScreen from './screens/ExperienceListDestinationScreen'
import ExperienceListLeadsScreen from './screens/ExperienceListLeadsScreen/ExperienceListLeadsScreen'
import ResetPasswordScreen from './screens/ResetPassword/ResetPasswordScreen'
import ForgotPasswordScreen from './screens/ForgotPassword/ForgotPasswordScreen'
import ExperienceListOrdersScreen from './screens/ExperienceListOrdersScreen'
import UserListOrdersScreen from './screens/UserListOrdersScreen'
import ScrollToTop from './components/ScrollToTop/ScrollToTop.js'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import PublicOrgScreen from './screens/Organizations/PublicOrgScreen/PublicOrgScreen.js'
import MembershipReviewScreen from './screens/Organizations/MembershipFlow/MembershipReviewScreen/MembershipReviewScreen.js'
import MembershipOptionsScreen from './screens/Organizations/MembershipFlow/MembershipOptions/MembershipOptionsScreen.js'
import MemberInfoScreen from './screens/Organizations/MembershipFlow/MemberInfoScreen/MemberInfoScreen.js'
import MembershipPaymentScreen from './screens/Organizations/MembershipFlow/MembershipPaymentScreen/MembershipPaymentScreen.js'
import ErrorBoundaryScreen from './screens/ErrorBoundaryScreen/ErrorBoundaryScreen.js'
import NotFoundScreen from './screens/NotFoundScreen/NotFoundScreen.js'
import AdminOrgListScreen from './screens/Organizations/AdminOrgListScreen/AdminOrgListScreen.js'
import ManageOrgListScreen from './screens/Organizations/ManageOrgListScreen/ManageOrgListScreen.js'
import OrgManageScreen from './screens/Organizations/OrgManageScreen/OrgManageScreen.js'
import OrgManagePayoutsScreen from './screens/Organizations/OrgManagePayoutsScreen/OrgManagePayoutsScreen.js'
import OrgManageRolesScreen from './screens/Organizations/OrgManageRolesScreen/OrgManageRolesScreen.js'
import OrgManageExperiencesScreen from './screens/Organizations/OrgManageExperiencesScreen/OrgManageExperiencesScreen.js'
import ExperienceManageLayout from './components/ExperienceManageLayout.js'
import DealListScreen from './screens/DealListScreen/DealListScreen.js'

const App = () => {
	const PayPalClientId =
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PAYPAL_CLIENT_ID
			: process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID
	return (
		<PayPalScriptProvider
			options={{
				'client-id': PayPalClientId,
				'disable-funding': 'credit',
				'enable-funding': 'venmo',
			}}
		>
			<ErrorBoundaryScreen>
				<Router>
					<ScrollToTop />
					<Header />
					<SubHeader />

					<Routes>
						<Route path='/' element={<HomeScreen />} />
						<Route path='/order/:id' element={<OrderScreen />} />
						<Route
							path='/org/:slug/:membershipprogramid/membership/:usermembershipid'
							element={<MembershipPaymentScreen />}
						/>
						<Route path='/login' element={<LoginScreen />} />
						<Route path='/travelerinfo' element={<TravelerInfoScreen />} />
						<Route
							path='/org/:slug/member-info'
							element={<MemberInfoScreen />}
						/>
						<Route
							path='/:experienceid/options'
							element={<OrderOptionsScreen />}
						/>
						<Route
							path='/org/:slug/membership-options'
							element={<MembershipOptionsScreen />}
						/>
						<Route path='/payment' element={<PaymentScreen />} />
						<Route path='/register' element={<RegisterScreen />} />
						<Route path='/forgotPassword' element={<ForgotPasswordScreen />} />
						<Route path='/profile' element={<ProfileScreen />} />
						<Route
							path='/resetpassword/:resetToken'
							element={<ResetPasswordScreen />}
						/>
						<Route path='/admin/userlist' element={<UserListScreen />} />
						<Route
							path='/admin/userlist/search/:keyword'
							element={<UserListScreen />}
						/>
						<Route
							path='/admin/experiencelist'
							element={<ExperienceListScreen />}
						/>
						<Route path='/admin/deallist' element={<DealListScreen />} />
						<Route path='/admin/org-list' element={<AdminOrgListScreen />} />
						<Route path='/manage/org-list' element={<ManageOrgListScreen />} />
						<Route path='/admin/orderlist' element={<OrderListScreen />} />
						<Route
							path='/admin/experience/:id/itinerary/:itinid'
							element={<ExperienceEditItineraryScreen />}
						/>
						<Route
							path='/admin/experience/:id/tripoption/:tripoptionid'
							element={<ExperienceEditTripOptionScreen />}
						/>
						<Route
							path='/admin/experience/:id/destination/:destid'
							element={<ExperienceEditDestinationScreen />}
						/>

						<Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
						<Route path='/:id/review' element={<ReviewScreen />} />
						<Route
							path='/org/:slug/:membershipprogramid/review-membership'
							element={<MembershipReviewScreen />}
						/>

						<Route path='/home' element={<HomeScreen />} />
						<Route path='/experiences/:id' element={<ExperienceScreen />} />
						<Route path='/org/:slug' element={<PublicOrgScreen />} />
						<Route
							path='/admin/experience/:id/orgs'
							element={<ExperienceListOrgsScreen />}
						/>
						<Route path='/org/:slug/manage' element={<OrgManageScreen />} />
						<Route
							path='/org/:slug/manage/payouts'
							element={<OrgManagePayoutsScreen />}
						/>
						<Route
							path='/org/:slug/manage/roles'
							element={<OrgManageRolesScreen />}
						/>
						<Route
							path='/org/:slug/manage/experiences'
							element={<OrgManageExperiencesScreen />}
						/>

						<Route
							path='/admin/user/:id/orders'
							element={<UserListOrdersScreen />}
						/>

						<Route
							path='/admin/experience/'
							element={<ExperienceManageLayout />}
						>
							<Route
								path=':id/details'
								element={<ExperienceEditDetailsScreen />}
							/>
							<Route
								path=':id/itinerary'
								element={<ExperienceListItineraryScreen />}
							/>
							<Route
								path=':id/accommodations'
								element={<ExperienceEditAccScreen />}
							/>
							<Route
								path=':id/destination'
								element={<ExperienceListDestinationScreen />}
							/>
							<Route
								path=':id/tripoption'
								element={<ExperienceListTripOptionScreen />}
							/>
							<Route
								path=':id/orders'
								element={<ExperienceListOrdersScreen />}
							/>
							<Route path=':id/leads' element={<ExperienceListLeadsScreen />} />
						</Route>

						<Route
							path='/org/:slug/manage/experience/'
							element={<ExperienceManageLayout />}
						>
							<Route
								path=':id/details'
								element={<ExperienceEditDetailsScreen />}
							/>
							<Route
								path=':id/itinerary'
								element={<ExperienceListItineraryScreen />}
							/>
							<Route
								path=':id/accommodations'
								element={<ExperienceEditAccScreen />}
							/>
							<Route
								path=':id/destination'
								element={<ExperienceListDestinationScreen />}
							/>
							<Route
								path=':id/tripoption'
								element={<ExperienceListTripOptionScreen />}
							/>
							<Route
								path=':id/orders'
								element={<ExperienceListOrdersScreen />}
							/>
							<Route path=':id/leads' element={<ExperienceListLeadsScreen />} />
						</Route>

						<Route path='*' element={<NotFoundScreen />} />
					</Routes>
					<Footer />
					<AuthVerify logout={logout} />
				</Router>
			</ErrorBoundaryScreen>
		</PayPalScriptProvider>
	)
}

export default App
