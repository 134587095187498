import React, { useEffect } from 'react'
import { NavLink, Link, useParams, useNavigate } from 'react-router-dom'
import { Table, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUser } from '../services/actions/userActions'

const UserListScreen = () => {
	const { keyword } = useParams()
	const navigate = useNavigate()

	const dispatch = useDispatch()

	const userList = useSelector((state) => state.userList)
	const { loading, error, users } = userList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userDelete = useSelector((state) => state.userDelete)
	const { success: successDelete } = userDelete

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listUsers(keyword || '')) // Ensure keyword has a fallback
		} else {
			navigate('/login') // Redirect to login if not admin
		}
	}, [dispatch, navigate, successDelete, userInfo, keyword])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			dispatch(deleteUser(id))
		}
	}

	return (
		<Container>
			<div className='my-4'>
				<h1>Users</h1>
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>FULL NAME</th>
								<th>EMAIL</th>
								<th>ADMIN</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{users.map((user) => (
								<tr key={user._id}>
									<td>
										<Link to={`/admin/user/${user._id}/edit`}>{user.name}</Link>
									</td>
									<td>{user.email}</td>
									<td>
										{user.isAdmin ? (
											<i
												className='fas fa-check'
												style={{ color: 'green' }}
											></i>
										) : (
											<i className='fas fa-times' style={{ color: 'red' }}></i>
										)}
									</td>
									<td>
										<NavLink to={`/admin/user/${user._id}/edit`}>
											<Button variant='light' className='btn-sm'>
												<i className='fas fa-edit'></i>
											</Button>
										</NavLink>
										<Button
											variant='danger'
											className='btn-sm'
											onClick={() => deleteHandler(user._id)}
										>
											<i className='fas fa-trash'></i>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</div>
		</Container>
	)
}

export default UserListScreen
