import React from 'react'
import { Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Experience = ({ order }) => {
	const { experience } = order

	return (
		<>
			{experience && (
				<NavLink
					style={{ maxWidth: '200px' }}
					to={`/experiences/${experience._id}`}
				>
					<li
						className='cards__item m-0'
						style={{
							boxShadow: '0px 0px 15px #f5f5f5',
							flex: '0 0 200px',
						}}
					>
						<div className='cards__item__link'>
							<figure
								className='cards__item__pic-wrap-no-box'
								data-category={experience.type}
							>
								<img
									className='cards__item__img'
									alt='Travel Pic'
									src={experience.image}
								/>
							</figure>
							<div className='cards__item__info'>
								<h5 className='cards__item__title mb-2'>{experience.name}</h5>
							</div>

							<Button
								href={`/experiences/${experience._id}`}
								className='btn-block'
								type='button'
							>
								View Experience
							</Button>
						</div>
					</li>
				</NavLink>
			)}
		</>
	)
}

export default Experience
