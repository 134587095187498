import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
	experienceListReducer,
	orgExperienceListReducer,
	experienceDetailsReducer,
	experienceDeleteReducer,
	experienceCreateReducer,
	experienceUpdateReducer,
	experienceItineraryItemCreateReducer,
	experienceTripOptionCreateReducer,
} from './services/reducers/experienceReducers'
import { flowReducer } from './services/reducers/flowReducers'
import { membershipFlowReducer } from './services/reducers/membershipFlowReducers'
import {
	userLoginReducer,
	userRegisterReducer,
	userDetailsReducer,
	userUpdateProfileReducer,
	userListReducer,
	userDeleteReducer,
	userUpdateReducer,
} from './services/reducers/userReducers'
import {
	leadRegisterReducer,
	leadListExperienceReducer,
} from './services/reducers/leadReducers'
import {
	orderCreateReducer,
	orderDetailsReducer,
	orderPayReducer,
	orderListMyReducer,
	orderListExperienceReducer,
	orderListUserReducer,
	orderListReducer,
	orderDeliverReducer,
	orderListExperienceTravelersReducer,
	orderDeleteReducer,
} from './services/reducers/orderReducers'
import {
	orgListReducer,
	orgDetailsReducer,
	//orgDeleteReducer,
	// orgCreateReducer,
	// orgUpdateReducer,
} from './services/reducers/orgReducers'
import {
	userMembershipCreateReducer,
	userMembershipDetailsReducer,
	userMembershipPayReducer,
	userMembershipListMyReducer,
	userMembershipListOrgReducer,
} from './services/reducers/userMembershipReducers'
import { dealListReducer } from './services/reducers/dealReducers'

const reducer = combineReducers({
	experienceList: experienceListReducer,
	orgExperienceList: orgExperienceListReducer,
	experienceDetails: experienceDetailsReducer,
	experienceDelete: experienceDeleteReducer,
	experienceCreate: experienceCreateReducer,
	experienceUpdate: experienceUpdateReducer,
	experienceItineraryItemCreate: experienceItineraryItemCreateReducer,
	experienceTripOptionCreate: experienceTripOptionCreateReducer,
	flow: flowReducer,
	membershipFlow: membershipFlowReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userDetails: userDetailsReducer,
	userUpdateProfile: userUpdateProfileReducer,
	userList: userListReducer,
	userDelete: userDeleteReducer,
	userUpdate: userUpdateReducer,
	orderCreate: orderCreateReducer,
	orderDelete: orderDeleteReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	orderListMy: orderListMyReducer,
	orderListExperience: orderListExperienceReducer,
	orderListUser: orderListUserReducer,
	orderList: orderListReducer,
	orderDeliver: orderDeliverReducer,
	experienceTravelers: orderListExperienceTravelersReducer,
	leadRegister: leadRegisterReducer,
	leadListExperience: leadListExperienceReducer,
	orgList: orgListReducer,
	orgDetails: orgDetailsReducer,
	userMembershipCreate: userMembershipCreateReducer,
	userMembershipDetails: userMembershipDetailsReducer,
	userMembershipPay: userMembershipPayReducer,
	userMembershipListMy: userMembershipListMyReducer,
	userMembershipListOrg: userMembershipListOrgReducer,
	dealList: dealListReducer,
})

const flowItemFromStorage = localStorage.getItem('flowItem')
	? JSON.parse(localStorage.getItem('flowItem'))
	: {}

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

const paymentStructureFromStorage = localStorage.getItem('paymentStructure')
	? JSON.parse(localStorage.getItem('paymentStructure'))
	: null

const flowMembershipProgramFromStorage = localStorage.getItem(
	'flowMembershipProgram'
)
	? JSON.parse(localStorage.getItem('flowMembershipProgram'))
	: null

const flowMembershipOrgFromStorage = localStorage.getItem('flowMembershipOrg')
	? JSON.parse(localStorage.getItem('flowMembershipOrg'))
	: null

const orderInfoFromStorage = localStorage.getItem('orderInfo')
	? JSON.parse(localStorage.getItem('orderInfo'))
	: {
			experiencePrice: 0,
			experienceTaxes: 0,
			addOnsPrice: 0,
			addOnsTaxes: 0,
			totalProductAmount: 0,
			totalTaxesAmount: 0,
			totalOrderAmount: 0,
			depositAmount: 0,
			finalPaymentDueDate: null,
			addOns: [],
	  }

const initialState = {
	flow: {
		flowItem: flowItemFromStorage,
		paymentStructure: paymentStructureFromStorage,
		orderInfo: orderInfoFromStorage,
	},

	membershipFlow: {
		flowMembershipProgram: flowMembershipProgramFromStorage,
		flowMembershipOrg: flowMembershipOrgFromStorage,
	},

	userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
)

export default store
