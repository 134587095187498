import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
	Table,
	Button,
	Row,
	Col,
	Container,
	Modal,
	Form,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import DateCard from '../../../components/DateCard/DateCard'
import { getOrgDetails } from '../../../services/actions/orgActions'
import { getUserDetails } from '../../../services/actions/userActions'
import {
	createOrgExperience,
	listOrgExperiences,
} from '../../../services/actions/experienceActions'
import OrgAdminNav from '../../../components/OrgAdminNav/OrgAdminNav'

const OrgManageExperiencesScreen = () => {
	const dispatch = useDispatch()
	const { slug: orgSlug } = useParams()
	const navigate = useNavigate()

	const [showModal, setShowModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails
	const orgExperienceList = useSelector((state) => state.orgExperienceList)
	const { experiences: orgExperiences, loading } = orgExperienceList

	const [newExperience, setNewExperience] = useState({
		name: '',
		type: 'Hosted',
		experienceOrgs: [
			{
				org: org._id,
				orgId: org._id,
				orgRole: 'managingOrg',
			},
		],
		taxRate: org.experienceServiceRate,
	})

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: userCredInfo } = userLogin
	const { user } = useSelector((state) => state.userDetails)
	const {
		loading: createExperienceLoading,
		success: createExperienceSuccess,
		experience: createdExperience,
	} = useSelector((state) => state.experienceCreate)

	useEffect(() => {
		// Fetch organization details only if not already fetched or slug mismatches
		if (!org?._id || org?.slug !== orgSlug) {
			dispatch(getOrgDetails(orgSlug))
		}
		if (org?._id) {
			dispatch(listOrgExperiences(org._id))
		}
	}, [dispatch, orgSlug, org?._id, org?.slug])

	useEffect(() => {
		// Reset experience state when org is loaded
		if (org?._id) {
			setNewExperience({
				name: '',
				type: 'Hosted',
				experienceOrgs: [
					{
						org: org._id,
						orgId: org._id,
						orgRole: 'managingOrg',
					},
				],
				taxRate: org.experienceServiceRate,
			})
		}
	}, [org])

	useEffect(() => {
		// Redirect to login if user credentials are missing
		if (!userCredInfo) {
			navigate(`/login?redirect=org/${orgSlug}/manage`)
		} else if (!user?._id) {
			// Fetch user details if not already present
			dispatch(getUserDetails('profile'))
		} else {
			// Check user roles and redirect if unauthorized
			const userMembership = user.orgRoles?.find(
				(role) => role.orgId === org?._id
			)
			const myUserType = userCredInfo.isAdmin
				? 'admin'
				: userMembership?.roleType

			if (!(myUserType === 'admin' || myUserType === 'manager')) {
				navigate('/')
			}
		}
	}, [dispatch, navigate, userCredInfo, user, org, orgSlug])

	useEffect(() => {
		// Fetch org details again if `createExperienceSuccess` is true
		if (createExperienceSuccess && org) {
			dispatch(listOrgExperiences(org?._id))
		}
	}, [dispatch, org, createExperienceSuccess])

	const handleAddMemberClick = () => {
		setShowModal(true)
	}

	const handleCloseModal = () => {
		setErrorMessage('')
		setShowModal(false)
		setNewExperience({
			name: '',
			type: 'Hosted',
			experienceOrgs: [
				{
					org: org?._id,
					orgRole: 'managingOrg',
				},
			],
		})
	}

	const validateForm = () => {
		// Basic form validations
		if (!newExperience.name) return 'Experience name is required'
		if (!newExperience.type) return 'Please select the experience host'

		// Check if the email already exists for the selected membership program
		const isDuplicateExperience = orgExperiences?.some(
			(orgExperience) => orgExperience.name === newExperience.name
		)

		if (isDuplicateExperience) {
			return 'An experience with this name already exists'
		}

		return null // No validation errors
	}

	const handleSaveExperience = () => {
		const error = validateForm()
		if (error) {
			setErrorMessage(error) // Set error message if validation fails
			return
		}

		newExperience.org = org?._id
		// Dispatch action to save the new member
		dispatch(createOrgExperience(newExperience))
		handleCloseModal() // Close modal after saving
	}

	return (
		<>
			<OrgAdminNav />
			<Container className='my-4'>
				{org?.slug !== orgSlug ? (
					<Loader />
				) : (
					<>
						<Row className='align-items-center'>
							<Col>
								<h1>Experiences</h1>
							</Col>
							<Col className='text-right'>
								<Button className='my-3' onClick={handleAddMemberClick}>
									<i className='fas fa-plus'></i> Create Experience
								</Button>
							</Col>
						</Row>

						{orgExperiences?.length > 0 ? (
							<Table striped bordered hover responsive className='table-sm'>
								<thead>
									<tr>
										<th style={{ verticalAlign: 'middle' }}>EXPERIENCE</th>
										<th style={{ verticalAlign: 'middle' }}>PRICE</th>
										<th style={{ verticalAlign: 'middle' }}>ACCESS LEVEL</th>
										<th
											style={{
												verticalAlign: 'middle',
												maxWidth: '75px',
												minWidth: '50px',
											}}
										></th>
									</tr>
								</thead>
								<tbody>
									{orgExperiences?.map((experience) => (
										<tr key={experience._id}>
											<td style={{ verticalAlign: 'middle' }}>
												<Row className='m-1 align-items-center'>
													<DateCard date={experience.experienceStartDate} />
													{experience?.orgRole === 'managingOrg' ? (
														<Link
															className='ml-3'
															to={`/org/${orgSlug}/manage/experience/${experience._id}/details`}
														>
															{experience.name}
														</Link>
													) : (
														<span className='ml-3'>{experience.name}</span>
													)}
												</Row>
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												${experience.fullPriceWithTax.toFixed(2)}
											</td>
											<td
												style={{
													verticalAlign: 'middle',
												}}
											>
												{experience?.orgRole === 'managingOrg'
													? 'Edit Access'
													: 'View Only'}
											</td>
											<td
												style={{
													verticalAlign: 'middle',
													maxWidth: '75px',
													minWidth: '50px',
												}}
											>
												<NavLink
													to='route'
													target='_blank'
													onClick={(event) => {
														event.preventDefault()
														window.open(`/experiences/${experience._id}`)
													}}
												>
													<Button variant='light' className='btn-sm'>
														<i className='fas fa-external-link-square-alt fa-lg'></i>
													</Button>
												</NavLink>
												{experience?.orgRole === 'managingOrg' && (
													<>
														<NavLink
															to={`/org/${orgSlug}/manage/experience/${experience._id}/details`}
														>
															<Button variant='light' className='btn-sm'>
																<i className='fas fa-pen-square fa-lg'></i>
															</Button>
														</NavLink>

														{/* <Button
														variant='light'
														className='btn-sm'
														//onClick={() => copyExperienceHandler(experience._id)}
													>
														<i className='fas fa-copy fa-lg'></i>
													</Button>
													<Button
														variant='danger'
														className='btn-sm'
														//onClick={() => deleteHandler(experience._id)}
													>
														<i className='fas fa-trash fa-lg'></i>
													</Button> */}
													</>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						) : (
							<h2>No experiences assigned to this organization</h2>
						)}
						<Modal
							show={showModal}
							onHide={handleCloseModal}
							dialogClassName='custom-modal'
						>
							<Modal.Header closeButton>
								<Modal.Title>Create New Experience</Modal.Title>
							</Modal.Header>
							<Modal.Body className='modal-body-scrollable'>
								<Form>
									<Form.Group controlId='experienceName'>
										<Form.Label>Experience Name*</Form.Label>
										<Form.Control
											required
											type='text'
											placeholder='Enter experience name'
											value={newExperience.name}
											onChange={(e) =>
												setNewExperience({
													...newExperience,
													name: e.target.value,
												})
											}
										/>
									</Form.Group>
									<Form.Group controlId='experienceType' className='mt-3'>
										<Form.Label>
											What website will host this experience?*
										</Form.Label>
										<Form.Check
											type='radio'
											id='hosted-host'
											label='Host on this website (recommended)'
											name='experienceType'
											value='Hosted'
											checked={newExperience.type === 'Hosted'}
											onChange={(e) =>
												setNewExperience({
													...newExperience,
													type: e.target.value,
												})
											}
											required
										/>
										<Form.Check
											type='radio'
											id='external-host'
											label='Link to an external website'
											name='experienceType'
											value='External'
											checked={newExperience.type === 'External'}
											onChange={(e) =>
												setNewExperience({
													...newExperience,
													type: e.target.value,
												})
											}
											required
										/>
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								{errorMessage && (
									<Col xs={12}>
										<Message variant='danger'>{errorMessage}</Message>
									</Col>
								)}
								<Row className='w-100'>
									<Col className='text-right'>
										<Button variant='secondary' onClick={handleCloseModal}>
											Close
										</Button>
										<Button variant='primary' onClick={handleSaveExperience}>
											Create New Experience
										</Button>
									</Col>
								</Row>
							</Modal.Footer>
						</Modal>
					</>
				)}
			</Container>
		</>
	)
}

export default OrgManageExperiencesScreen
