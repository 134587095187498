import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'
import axios from 'axios'

const ResetPasswordScreen = () => {
	const { resetToken } = useParams() // Get the reset token from route params
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)
	const [loading, setLoading] = useState(false)

	const validatePassword = (password, confirmPassword) => {
		if (password !== confirmPassword) {
			return 'Passwords do not match'
		}
		if (password.length < 8) {
			return 'Password must be at least 8 characters long.'
		}
		if (!/[A-Z]/.test(password)) {
			return 'Password must include at least one uppercase letter.'
		}
		if (!/[a-z]/.test(password)) {
			return 'Password must include at least one lowercase letter.'
		}
		if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
			return 'Password must include at least one special character.'
		}
		if (/\s/.test(password)) {
			return 'Password must not contain spaces.'
		}
		return false
	}

	const resetPasswordHandler = async (e) => {
		e.preventDefault()
		setError('')
		setLoading(true)

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const passwordErrors = validatePassword(password, confirmPassword)

		if (passwordErrors) {
			setError(passwordErrors)
			setLoading(false)
			return
		}

		try {
			const { data } = await axios.put(
				`/api/users/resetpassword/${resetToken}`,
				{ password },
				config
			)
			setSuccess(data.data)
			setLoading(false)
		} catch (error) {
			setError(error.response?.data?.message || 'Something went wrong')
			setLoading(false)
		}
	}

	return (
		<Container>
			<Meta title='I Do Tours | Reset Password' />
			<Row className='my-4'>
				<Col md={5}>
					<h2>Choose a New Password</h2>
					{error && <Message variant='danger'>{error}</Message>}
					{success && (
						<Message variant='success'>
							{success} <Link to='/login'>Login</Link>
						</Message>
					)}
					{loading && <Loader />}
					<Form onSubmit={resetPasswordHandler}>
						<Form.Group controlId='password'>
							<Form.Label>New Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Enter new password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
								disabled={success}
								autoComplete='new-password'
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='confirmPassword'>
							<Form.Label>Confirm New Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Confirm new password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								required
								disabled={success}
								autoComplete='new-password'
							></Form.Control>
						</Form.Group>

						<Button type='submit' variant='primary' disabled={success}>
							Save Password
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default ResetPasswordScreen
