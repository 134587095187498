import React, { useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const parseJwt = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]))
	} catch (e) {
		return null
	}
}

const AuthVerify = ({ logout }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const verifyToken = useCallback(() => {
		const user = JSON.parse(localStorage.getItem('userInfo'))
		if (user) {
			const decodedJwt = parseJwt(user.token)
			if (decodedJwt.exp * 1000 < Date.now()) {
				dispatch(logout())
				navigate('/login')
			}
		}
	}, [dispatch, logout, navigate])

	useEffect(() => {
		verifyToken()
	}, [verifyToken])

	useEffect(() => {
		verifyToken()
	}, [location, verifyToken]) // Re-run verification on route change

	return null
}

export default AuthVerify
