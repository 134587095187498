import React from 'react'
import { Outlet } from 'react-router-dom'
import DetailHeader from '../components/DetailHeader'
import OrgAdminNav from '../components/OrgAdminNav/OrgAdminNav'
import { Container } from 'react-bootstrap'

const ExperienceManageLayout = () => {
	const isManagePath = window.location.pathname.includes('manage')
	return (
		<div>
			{isManagePath && <OrgAdminNav />}
			<DetailHeader />
			<Container>
				<Outlet />
			</Container>
		</div>
	)
}

export default ExperienceManageLayout
