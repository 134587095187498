import {
	DEAL_LIST_REQUEST,
	DEAL_LIST_SUCCESS,
	DEAL_LIST_FAIL,
} from '../constants/dealConstants'

export const dealListReducer = (state = { deals: [] }, action) => {
	switch (action.type) {
		case DEAL_LIST_REQUEST:
			return { loading: true, deals: [] }
		case DEAL_LIST_SUCCESS:
			return { loading: false, deals: action.payload }
		case DEAL_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
